import * as React from 'react';

const LogoutIcon = props => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path d="M9 21H5C4.46957 21 3.96086 20.7893 3.58579 20.4142C3.21071 20.0391 3 19.5304 3 19L3 5C3 4.46957 3.21071 3.96086 3.58579 3.58579C3.96086 3.21071 4.46957 3 5 3L9 3" stroke="#181818" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M14 7L9 12L14 17" stroke="#181818" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M9 12L21 12" stroke="#181818" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
    )
}

export default LogoutIcon;