import * as React from 'react';

const FbSad = props => {
    return (
        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M18 9C18 11.3914 17.0486 13.68 15.3643 15.3643C13.68 17.0486 11.3914 18 9 18C6.60857 18 4.32 17.0486 2.63571 15.3643C0.951429 13.68 0 11.3914 0 9C0 6.60857 0.951429 4.32 2.63571 2.63572C4.32 0.951429 6.60857 0 9 0C11.3914 0 13.68 0.951429 15.3643 2.63572C17.0486 4.33286 18 6.62143 18 9Z" fill="url(#paint0_linear_6460_18297)" />
            <path d="M5.99146 14.3618C5.99146 14.5161 6.09431 14.6318 6.27431 14.6318C6.67289 14.6318 7.21289 13.9247 8.98717 13.9247C10.7615 13.9247 11.3143 14.6318 11.7 14.6318C11.88 14.6318 11.9829 14.5161 11.9829 14.3618C11.9829 13.9118 11.0443 12.3818 8.98717 12.3818C6.93003 12.3818 5.99146 13.9247 5.99146 14.3618Z" fill="url(#paint1_linear_6460_18297)" />
            <path d="M4.04991 9.89962C4.04991 8.98676 4.62848 8.25391 5.32276 8.25391C6.02991 8.25391 6.59562 8.98676 6.59562 9.89962C6.59562 10.2853 6.49276 10.6325 6.32562 10.9153C6.24848 11.0439 6.13276 11.1339 5.99133 11.1725C5.83705 11.211 5.59276 11.2625 5.32276 11.2625C5.05276 11.2625 4.80848 11.211 4.65419 11.1725C4.51276 11.1339 4.39705 11.0439 4.3199 10.9153C4.1399 10.6068 4.03705 10.2596 4.04991 9.89962ZM11.3913 9.89962C11.3913 8.98676 11.957 8.25391 12.6642 8.25391C13.3713 8.25391 13.937 8.98676 13.937 9.89962C13.937 10.2853 13.8342 10.6325 13.667 10.9153C13.6285 10.9796 13.577 11.031 13.5256 11.0696C13.4613 11.1082 13.397 11.1468 13.3328 11.1596C12.8956 11.2753 12.4328 11.2753 11.9956 11.1596C11.9313 11.1468 11.8542 11.1082 11.8028 11.0696C11.7385 11.031 11.6999 10.9668 11.6613 10.9153C11.4813 10.6068 11.3913 10.2596 11.3913 9.89962Z" fill="url(#paint2_linear_6460_18297)" />
            <path d="M4.04991 9.89962C4.04991 8.98676 4.62848 8.25391 5.32276 8.25391C6.02991 8.25391 6.59562 8.98676 6.59562 9.89962C6.59562 10.2853 6.49276 10.6325 6.32562 10.9153C6.24848 11.0439 6.13276 11.1339 5.99133 11.1725C5.83705 11.211 5.59276 11.2625 5.32276 11.2625C5.05276 11.2625 4.80848 11.211 4.65419 11.1725C4.51276 11.1339 4.39705 11.0439 4.3199 10.9153C4.1399 10.6068 4.03705 10.2596 4.04991 9.89962ZM11.3913 9.89962C11.3913 8.98676 11.957 8.25391 12.6642 8.25391C13.3713 8.25391 13.937 8.98676 13.937 9.89962C13.937 10.2853 13.8342 10.6325 13.667 10.9153C13.6285 10.9796 13.577 11.031 13.5256 11.0696C13.4613 11.1082 13.397 11.1468 13.3328 11.1596C12.8956 11.2753 12.4328 11.2753 11.9956 11.1596C11.9313 11.1468 11.8542 11.1082 11.8028 11.0696C11.7385 11.031 11.6999 10.9668 11.6613 10.9153C11.4813 10.6068 11.3913 10.2596 11.3913 9.89962Z" fill="black" />
            <path d="M5.19431 8.98678C5.33574 9.12821 5.3486 9.41106 5.20717 9.60392C5.0786 9.80963 4.84716 9.84821 4.70573 9.70678C4.56431 9.56535 4.55145 9.28249 4.69288 9.08963C4.82145 8.89678 5.04002 8.84535 5.19431 8.98678ZM12.4843 8.98678C12.6257 9.12821 12.6386 9.41106 12.51 9.60392C12.3686 9.80963 12.15 9.84821 12.0086 9.70678C11.8672 9.56535 11.8543 9.28249 11.9957 9.08963C12.1243 8.89678 12.3429 8.84535 12.4843 8.98678Z" fill="#4E506A" />
            <path d="M4.66707 5.79897C5.03992 5.63183 5.33564 5.55469 5.56707 5.55469C5.87564 5.55469 6.06849 5.68326 6.18421 5.90183C6.37706 6.27469 6.28707 6.35183 5.96564 6.41612C4.7185 6.67326 3.47135 7.4704 2.93135 7.98469C2.59707 8.29326 2.26278 7.94612 2.44278 7.67612C2.60993 7.39326 3.31707 6.42897 4.66707 5.79897ZM11.8028 5.90183C11.9185 5.69612 12.1113 5.55469 12.4199 5.55469C12.6513 5.55469 12.9471 5.63183 13.3199 5.79897C14.6828 6.42897 15.3771 7.39326 15.5442 7.66326C15.7114 7.93326 15.3899 8.2804 15.0556 7.97183C14.5156 7.4704 13.2685 6.6604 12.0214 6.40326C11.6871 6.35183 11.6099 6.27469 11.8028 5.90183Z" fill="url(#paint3_linear_6460_18297)" />
            <path d="M15.1843 18.0001C14.2586 18.0001 13.5 17.1643 13.5 16.1229C13.5 15.0815 13.8986 14.3872 14.22 13.7058C14.8886 12.2915 15.03 12.0986 15.1843 12.0986C15.3386 12.0986 15.48 12.2915 16.1486 13.7058C16.47 14.3872 16.8686 15.0815 16.8686 16.1229C16.8686 17.1643 16.11 18.0001 15.1843 18.0001Z" fill="url(#paint4_linear_6460_18297)" />
            <path d="M15.1842 15.3128C14.8113 15.3128 14.5156 14.9785 14.5156 14.5671C14.5156 14.1557 14.6699 13.8728 14.7985 13.6028C15.0685 13.0371 15.1199 12.96 15.1842 12.96C15.2485 12.96 15.2999 13.0371 15.5699 13.6028C15.6985 13.8728 15.8528 14.1557 15.8528 14.5542C15.8528 14.9785 15.5571 15.3128 15.1842 15.3128Z" fill="url(#paint5_linear_6460_18297)" />
            <defs>
                <linearGradient id="paint0_linear_6460_18297" x1="8.99572" y1="1.84928" x2="8.99572" y2="18.0043" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#FEEA70" />
                    <stop offset="1" stop-color="#F69B30" />
                </linearGradient>
                <linearGradient id="paint1_linear_6460_18297" x1="8.99575" y1="12.3797" x2="8.99575" y2="14.6297" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#472315" />
                    <stop offset="1" stop-color="#8B3A0E" />
                </linearGradient>
                <linearGradient id="paint2_linear_6460_18297" x1="8.99469" y1="8.25463" x2="8.99469" y2="11.2539" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#191A33" />
                    <stop offset="0.872" stop-color="#3B426A" />
                </linearGradient>
                <linearGradient id="paint3_linear_6460_18297" x1="8.99536" y1="5.55497" x2="8.99536" y2="8.10403" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#E78E0D" />
                    <stop offset="1" stop-color="#CB6000" />
                </linearGradient>
                <linearGradient id="paint4_linear_6460_18297" x1="15.1832" y1="16.9356" x2="15.1832" y2="13.1578" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#35CAFC" />
                    <stop offset="1" stop-color="#007EDB" />
                </linearGradient>
                <linearGradient id="paint5_linear_6460_18297" x1="15.1833" y1="12.9736" x2="15.1833" y2="15.3113" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#6AE1FF" stop-opacity="0.287" />
                    <stop offset="1" stop-color="#A8E3FF" stop-opacity="0.799" />
                </linearGradient>
            </defs>
        </svg>
    )
}

export default FbSad;