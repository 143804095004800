import React from 'react'

const ArrowRight = () => {
  return (
   <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M12 5L5 12M5 12L12 19M5 12H19" stroke="#181818" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
</svg>

  )
}

export default ArrowRight