import React from 'react'

const TimerIcon = () => {
  return (
    <div>
        <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M18.1459 19.1254H3.38426V15.5126C3.38533 15.1078 3.58535 14.7269 3.92498 14.4827L7.35868 12.0264C7.88753 11.6481 8.217 11.0701 8.26138 10.4427C8.30591 9.81531 8.0609 9.20061 7.59013 8.75855L3.78738 5.18874C3.53019 4.94602 3.38504 4.61608 3.38426 4.27161V1.88316H18.1459C18.4 1.88316 18.606 1.68744 18.606 1.4459C18.606 1.20451 18.4 1.00879 18.1459 1.00879H1.83235C1.5781 1.00879 1.37207 1.20451 1.37207 1.4459C1.37207 1.68744 1.5781 1.88316 1.83235 1.88316H2.46385V4.27161C2.46539 4.84955 2.7087 5.40332 3.14019 5.81086L6.94294 9.38053V9.38038C7.22355 9.64388 7.36945 10.0103 7.34298 10.3842C7.31663 10.758 7.12016 11.1025 6.80503 11.3278L3.37116 13.7842C2.80117 14.1941 2.46554 14.8333 2.46385 15.5126V19.1255L1.83235 19.1254C1.5781 19.1254 1.37207 19.3213 1.37207 19.5627C1.37207 19.8042 1.5781 19.9999 1.83235 19.9999H18.1459C18.4 19.9999 18.606 19.8042 18.606 19.5627C18.606 19.3213 18.4 19.1254 18.1459 19.1254V19.1254Z" fill="#4D4D4D" stroke="#4D4D4D"/>
<path d="M16.9183 20C16.7961 20 16.679 19.954 16.5927 19.8719C16.5064 19.7899 16.4579 19.6787 16.4581 19.5627V15.5128C16.4568 15.108 16.2566 14.727 15.9169 14.483L12.4831 12.0266C11.9543 11.6485 11.6247 11.0704 11.5803 10.443C11.5361 9.81556 11.7812 9.20074 12.2521 8.7588L16.0545 5.18898C16.3118 4.94642 16.4571 4.61632 16.458 4.27185V1.44614C16.458 1.20475 16.664 1.00903 16.9183 1.00903C17.1724 1.00903 17.3784 1.20476 17.3784 1.44614V4.27185C17.3769 4.84994 17.1334 5.40371 16.7016 5.81111L12.8993 9.38092C12.6186 9.64412 12.4723 10.0104 12.4987 10.3844C12.5252 10.7583 12.7215 11.1027 13.0368 11.328L16.4707 13.7844C17.0408 14.1942 17.3768 14.8336 17.3785 15.5128V19.5627C17.3786 19.6787 17.3301 19.7899 17.2438 19.8719C17.1575 19.954 17.0404 20 16.9183 20H16.9183Z" fill="#4D4D4D" stroke="#4D4D4D"/>
</svg>

    </div>
  )
}

export default TimerIcon