import React from 'react'

const Failed = () => {
  return (
    <svg width="70" height="70" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect width="50" height="50" rx="10" fill="#DA001A" fill-opacity="0.1"/>
<path d="M34.8854 20.3144L34.8425 20.2643L29.7426 15.1644L29.6855 15.1143C29.564 15.0429 29.4283 15 29.2855 15L18.5714 15.0002C16.5999 15.0002 15 16.6001 15 18.5715V31.4285C15 33.3999 16.5999 34.9998 18.5714 34.9998H31.4283C33.3997 34.9998 34.9997 33.3999 34.9997 31.4285V20.7144C34.9997 20.5787 34.9568 20.4358 34.8854 20.3143L34.8854 20.3144ZM29.9998 17.4359L32.564 20.0001H32.1426C30.964 20.0001 29.9998 19.0359 29.9998 17.8573V17.4359ZM33.5711 31.4284C33.5711 32.6069 32.6069 33.5712 31.4283 33.5712H18.5714C17.3928 33.5712 16.4285 32.6069 16.4285 31.4284V18.5714C16.4285 17.3929 17.3928 16.4286 18.5714 16.4286H28.5712V17.8571C28.5712 19.8286 30.1711 21.4285 32.1426 21.4285H33.5711V31.4284Z" fill="#DA001A" stroke="#DA001A" stroke-width="0.5"/>
<path d="M26.0134 25.3573L28.3633 27.7072L27.349 28.7216L24.999 26.3717L22.6491 28.7216L21.6348 27.7072L23.9847 25.3573L21.6348 23.0074L22.6491 21.993L24.999 24.343L27.349 21.993L28.3633 23.0074L26.0134 25.3573Z" fill="#DA001A" stroke="#DA001A" stroke-width="0.5"/>
</svg>

  )
}

export default Failed