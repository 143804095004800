import * as React from 'react';

const FbAngry = props => {
    return (
        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M18 9C18 11.3914 17.0486 13.68 15.3643 15.3643C13.68 17.0486 11.3914 18 9 18C6.60857 18 4.32 17.0486 2.63571 15.3643C0.951429 13.68 0 11.3914 0 9C0 6.60857 0.951429 4.32 2.63571 2.63571C4.32 0.951429 6.60857 0 9 0C11.3914 0 13.68 0.951429 15.3643 2.63571C17.0486 4.32 18 6.60857 18 9Z" fill="url(#paint0_linear_6484_19011)"/>
        <path d="M5.8501 15.2483C5.8501 15.8397 7.26438 15.7497 9.0001 15.7497C10.7358 15.7497 12.1501 15.8397 12.1501 15.2483C12.1501 14.5283 10.7358 14.0654 9.0001 14.0654C7.26438 14.0654 5.8501 14.5283 5.8501 15.2483Z" fill="black"/>
        <path d="M5.8501 15.2483C5.8501 15.8397 7.26438 15.7497 9.0001 15.7497C10.7358 15.7497 12.1501 15.8397 12.1501 15.2483C12.1501 14.5283 10.7358 14.0654 9.0001 14.0654C7.26438 14.0654 5.8501 14.5283 5.8501 15.2483Z" fill="url(#paint1_linear_6484_19011)"/>
        <path d="M4.05005 11.0573C4.05005 10.1702 4.65433 9.4502 5.40005 9.4502C6.14576 9.4502 6.75005 10.1702 6.75005 11.0573C6.75005 11.4302 6.64719 11.7645 6.46719 12.0345C6.37719 12.1631 6.26148 12.2531 6.10719 12.2788C5.94005 12.3173 5.68291 12.3688 5.40005 12.3688C5.11719 12.3688 4.86005 12.3302 4.69291 12.2788C4.55148 12.2402 4.42291 12.1502 4.33291 12.0345C4.14005 11.7516 4.05005 11.4045 4.05005 11.0573ZM11.25 11.0573C11.25 10.1702 11.8543 9.4502 12.6 9.4502C13.3458 9.4502 13.95 10.1702 13.95 11.0573C13.95 11.4302 13.8472 11.7645 13.6672 12.0345C13.59 12.1631 13.4615 12.2531 13.3072 12.2788C13.0758 12.3302 12.8315 12.3688 12.6 12.3688C12.3172 12.3688 12.06 12.3302 11.8929 12.2788C11.7515 12.2402 11.6229 12.1502 11.5329 12.0345C11.34 11.7516 11.25 11.4045 11.25 11.0573Z" fill="url(#paint2_linear_6484_19011)"/>
        <path d="M4.05005 11.0573C4.05005 10.1702 4.65433 9.4502 5.40005 9.4502C6.14576 9.4502 6.75005 10.1702 6.75005 11.0573C6.75005 11.4302 6.64719 11.7645 6.46719 12.0345C6.37719 12.1631 6.26148 12.2531 6.10719 12.2788C5.94005 12.3173 5.68291 12.3688 5.40005 12.3688C5.11719 12.3688 4.86005 12.3302 4.69291 12.2788C4.55148 12.2402 4.42291 12.1502 4.33291 12.0345C4.14005 11.7516 4.05005 11.4045 4.05005 11.0573ZM11.25 11.0573C11.25 10.1702 11.8543 9.4502 12.6 9.4502C13.3458 9.4502 13.95 10.1702 13.95 11.0573C13.95 11.4302 13.8472 11.7645 13.6672 12.0345C13.59 12.1631 13.4615 12.2531 13.3072 12.2788C13.0758 12.3302 12.8315 12.3688 12.6 12.3688C12.3172 12.3688 12.06 12.3302 11.8929 12.2788C11.7515 12.2402 11.6229 12.1502 11.5329 12.0345C11.34 11.7516 11.25 11.4045 11.25 11.0573Z" fill="black"/>
        <path d="M5.59287 10.5041C5.59287 10.5298 5.60573 10.5555 5.60573 10.5812C5.60573 10.8126 5.41287 10.9926 5.16859 10.9926C4.9243 10.9926 4.73145 10.8126 4.73145 10.5812C4.73145 10.4912 4.77002 10.4012 4.82144 10.3369C5.05287 10.3883 5.32287 10.4526 5.59287 10.5041ZM12.2529 10.9926C12.0214 10.9926 11.8157 10.8255 11.8157 10.6069C12.1114 10.5683 12.3943 10.5169 12.6772 10.4526C12.69 10.4912 12.7029 10.5426 12.7029 10.5812C12.7029 10.7998 12.4972 10.9926 12.2529 10.9926Z" fill="#4F4F67"/>
        <path d="M10.1186 8.54959C10.1186 8.04816 10.2986 7.86816 10.62 7.86816C10.9414 7.86816 11.0829 8.17673 11.1857 9.06388C12.4586 9.06388 14.6057 8.45959 14.8114 8.45959C15.0171 8.45959 15.12 8.54959 15.1714 8.75531C15.21 8.93531 15.1457 9.10245 14.9271 9.20531C14.2329 9.52673 12.1757 10.2596 10.44 10.2596C10.2471 10.2596 10.1186 10.1567 10.1186 9.92531V8.54959ZM6.80142 9.07673C6.90428 8.18959 7.05856 7.88102 7.36713 7.88102C7.68856 7.88102 7.86857 8.04816 7.86857 8.56245V9.95102C7.86857 10.1953 7.74 10.2853 7.54714 10.2853C5.81142 10.2853 3.76714 9.55245 3.05999 9.23102C2.84142 9.12816 2.77714 8.96102 2.81571 8.78102C2.86714 8.5753 2.96999 8.48531 3.17571 8.48531C3.38142 8.47245 5.54142 9.07673 6.80142 9.07673Z" fill="black"/>
        <path d="M10.1186 8.54959C10.1186 8.04816 10.2986 7.86816 10.62 7.86816C10.9414 7.86816 11.0829 8.17673 11.1857 9.06388C12.4586 9.06388 14.6057 8.45959 14.8114 8.45959C15.0171 8.45959 15.12 8.54959 15.1714 8.75531C15.21 8.93531 15.1457 9.10245 14.9271 9.20531C14.2329 9.52673 12.1757 10.2596 10.44 10.2596C10.2471 10.2596 10.1186 10.1567 10.1186 9.92531V8.54959ZM6.80142 9.07673C6.90428 8.18959 7.05856 7.88102 7.36713 7.88102C7.68856 7.88102 7.86857 8.04816 7.86857 8.56245V9.95102C7.86857 10.1953 7.74 10.2853 7.54714 10.2853C5.81142 10.2853 3.76714 9.55245 3.05999 9.23102C2.84142 9.12816 2.77714 8.96102 2.81571 8.78102C2.86714 8.5753 2.96999 8.48531 3.17571 8.48531C3.38142 8.47245 5.54142 9.07673 6.80142 9.07673Z" fill="url(#paint3_linear_6484_19011)"/>
        <defs>
        <linearGradient id="paint0_linear_6484_19011" x1="9" y1="0" x2="9" y2="12.0949" gradientUnits="userSpaceOnUse">
        <stop stop-color="#E04300"/>
        <stop offset="1" stop-color="#FFA320"/>
        </linearGradient>
        <linearGradient id="paint1_linear_6484_19011" x1="9.00032" y1="14.2906" x2="9.00032" y2="15.7497" gradientUnits="userSpaceOnUse">
        <stop stop-color="#3D0D00"/>
        <stop offset="1" stop-color="#661C04"/>
        </linearGradient>
        <linearGradient id="paint2_linear_6484_19011" x1="8.99965" y1="9.45063" x2="8.99965" y2="12.3756" gradientUnits="userSpaceOnUse">
        <stop stop-color="#191A33"/>
        <stop offset="0.872" stop-color="#3B426A"/>
        </linearGradient>
        <linearGradient id="paint3_linear_6484_19011" x1="8.99954" y1="10.4992" x2="8.99954" y2="7.87459" gradientUnits="userSpaceOnUse">
        <stop stop-color="#9A2F00"/>
        <stop offset="1" stop-color="#D44800"/>
        </linearGradient>
        </defs>
        </svg>
        
        
    )
}

export default FbAngry;