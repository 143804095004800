import * as React from 'react';

const FbWow = props => {
    return (
        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M18 9C18 11.3914 17.0486 13.68 15.3643 15.3643C13.68 17.0486 11.3914 18 9 18C6.60857 18 4.32 17.0486 2.63572 15.3643C0.951429 13.68 0 11.3914 0 9C0 6.60857 0.951429 4.32 2.63572 2.63572C4.33286 0.951429 6.62143 0 9 0C11.3914 0 13.68 0.951429 15.3643 2.63572C17.0614 4.33286 18 6.62143 18 9Z" fill="url(#paint0_linear_6460_18289)" />
            <path d="M6.35139 12.2529C6.17139 14.3229 7.17425 15.75 8.99997 15.75C10.8385 15.75 11.8285 14.3229 11.6485 12.2529C11.4685 10.17 10.3885 9 8.99997 9C7.61139 9 6.53139 10.1829 6.35139 12.2529Z" fill="url(#paint1_linear_6460_18289)" />
            <path d="M3.94714 6.19698C3.94714 5.27127 4.57714 4.5127 5.34857 4.5127C6.12 4.5127 6.75 5.27127 6.75 6.19698C6.75 7.13555 6.12 7.88127 5.34857 7.88127C4.57714 7.88127 3.94714 7.1227 3.94714 6.19698ZM11.25 6.19698C11.25 5.27127 11.88 4.5127 12.6514 4.5127C13.4229 4.5127 14.0529 5.27127 14.0529 6.19698C14.0529 7.13555 13.4229 7.88127 12.6514 7.88127C11.88 7.88127 11.25 7.1227 11.25 6.19698Z" fill="url(#paint2_linear_6460_18289)" />
            <path d="M3.94714 6.19698C3.94714 5.27127 4.57714 4.5127 5.34857 4.5127C6.12 4.5127 6.75 5.27127 6.75 6.19698C6.75 7.13555 6.12 7.88127 5.34857 7.88127C4.57714 7.88127 3.94714 7.1227 3.94714 6.19698ZM11.25 6.19698C11.25 5.27127 11.88 4.5127 12.6514 4.5127C13.4229 4.5127 14.0529 5.27127 14.0529 6.19698C14.0529 7.13555 13.4229 7.88127 12.6514 7.88127C11.88 7.88127 11.25 7.1227 11.25 6.19698Z" fill="black" />
            <path d="M5.04003 5.14311C5.24575 5.19454 5.36146 5.42597 5.29718 5.67025C5.23289 5.91454 5.01432 6.08168 4.8086 6.03025C4.60289 5.97882 4.48718 5.74739 4.55146 5.50311C4.61575 5.25882 4.83432 5.09168 5.04003 5.14311ZM12.5357 5.20739C12.7672 5.25882 12.8957 5.52882 12.8315 5.79882C12.7543 6.06882 12.51 6.24882 12.2786 6.19739C12.0472 6.14597 11.9186 5.88882 11.9829 5.60597C12.06 5.33597 12.3043 5.15597 12.5357 5.20739Z" fill="#4E506A" />
            <path d="M12.4585 1.9152C12.5228 1.9152 12.5742 1.90234 12.6385 1.90234C13.1914 1.90234 13.7571 2.13377 14.1814 2.53234C14.2585 2.60949 14.3099 2.71234 14.3099 2.8152C14.3099 2.91806 14.2714 3.02092 14.2071 3.11092C14.1685 3.14949 14.1299 3.18806 14.0785 3.20092C14.0271 3.22663 13.9757 3.23949 13.9242 3.23949C13.8728 3.23949 13.8214 3.23949 13.7699 3.21377C13.7185 3.20092 13.6799 3.16234 13.6414 3.13663C13.3328 2.84092 12.9085 2.68663 12.5228 2.7252C12.3942 2.73806 12.2657 2.77663 12.1499 2.84092C12.0342 2.9052 11.9314 2.9952 11.8542 3.09806C11.8285 3.13663 11.7899 3.1752 11.7385 3.20092C11.6999 3.22663 11.6485 3.23949 11.5971 3.25234C11.5457 3.2652 11.4942 3.25234 11.4428 3.23949C11.3914 3.22663 11.3399 3.20092 11.3014 3.1752C11.2114 3.11092 11.1599 3.00806 11.1471 2.9052C11.1342 2.80234 11.1599 2.68663 11.2242 2.60949C11.3657 2.41663 11.5457 2.24949 11.7642 2.13377C11.9828 2.01806 12.2142 1.92806 12.4585 1.9152ZM3.83137 2.53234C4.2428 2.13377 4.79566 1.9152 5.37423 1.90234C5.64423 1.90234 5.91423 1.95377 6.15851 2.08234C6.4028 2.19806 6.60851 2.37806 6.77566 2.59663C6.83994 2.68663 6.86566 2.78949 6.8528 2.89234C6.83994 2.9952 6.78851 3.09806 6.69851 3.16234C6.65994 3.18806 6.60851 3.21377 6.55708 3.22663C6.50566 3.23949 6.45423 3.23949 6.4028 3.23949C6.35137 3.22663 6.29994 3.21377 6.26137 3.18806C6.2228 3.16234 6.17137 3.12377 6.14566 3.0852C6.06851 2.98234 5.96566 2.89234 5.84994 2.82806C5.73423 2.76377 5.60566 2.7252 5.47708 2.71234C5.10423 2.67377 4.66708 2.84092 4.35851 3.12377C4.31994 3.16234 4.28137 3.18806 4.22994 3.20092C4.17851 3.21377 4.12708 3.22663 4.07566 3.22663C4.02423 3.22663 3.9728 3.21377 3.92137 3.18806C3.86994 3.16234 3.83137 3.13663 3.7928 3.09806C3.71566 3.02092 3.68994 2.91806 3.68994 2.80234C3.71566 2.71234 3.75423 2.60949 3.83137 2.53234Z" fill="black" />
            <path d="M12.4585 1.9152C12.5228 1.9152 12.5742 1.90234 12.6385 1.90234C13.1914 1.90234 13.7571 2.13377 14.1814 2.53234C14.2585 2.60949 14.3099 2.71234 14.3099 2.8152C14.3099 2.91806 14.2714 3.02092 14.2071 3.11092C14.1685 3.14949 14.1299 3.18806 14.0785 3.20092C14.0271 3.22663 13.9757 3.23949 13.9242 3.23949C13.8728 3.23949 13.8214 3.23949 13.7699 3.21377C13.7185 3.20092 13.6799 3.16234 13.6414 3.13663C13.3328 2.84092 12.9085 2.68663 12.5228 2.7252C12.3942 2.73806 12.2657 2.77663 12.1499 2.84092C12.0342 2.9052 11.9314 2.9952 11.8542 3.09806C11.8285 3.13663 11.7899 3.1752 11.7385 3.20092C11.6999 3.22663 11.6485 3.23949 11.5971 3.25234C11.5457 3.2652 11.4942 3.25234 11.4428 3.23949C11.3914 3.22663 11.3399 3.20092 11.3014 3.1752C11.2114 3.11092 11.1599 3.00806 11.1471 2.9052C11.1342 2.80234 11.1599 2.68663 11.2242 2.60949C11.3657 2.41663 11.5457 2.24949 11.7642 2.13377C11.9828 2.01806 12.2142 1.92806 12.4585 1.9152ZM3.83137 2.53234C4.2428 2.13377 4.79566 1.9152 5.37423 1.90234C5.64423 1.90234 5.91423 1.95377 6.15851 2.08234C6.4028 2.19806 6.60851 2.37806 6.77566 2.59663C6.83994 2.68663 6.86566 2.78949 6.8528 2.89234C6.83994 2.9952 6.78851 3.09806 6.69851 3.16234C6.65994 3.18806 6.60851 3.21377 6.55708 3.22663C6.50566 3.23949 6.45423 3.23949 6.4028 3.23949C6.35137 3.22663 6.29994 3.21377 6.26137 3.18806C6.2228 3.16234 6.17137 3.12377 6.14566 3.0852C6.06851 2.98234 5.96566 2.89234 5.84994 2.82806C5.73423 2.76377 5.60566 2.7252 5.47708 2.71234C5.10423 2.67377 4.66708 2.84092 4.35851 3.12377C4.31994 3.16234 4.28137 3.18806 4.22994 3.20092C4.17851 3.21377 4.12708 3.22663 4.07566 3.22663C4.02423 3.22663 3.9728 3.21377 3.92137 3.18806C3.86994 3.16234 3.83137 3.13663 3.7928 3.09806C3.71566 3.02092 3.68994 2.91806 3.68994 2.80234C3.71566 2.71234 3.75423 2.60949 3.83137 2.53234Z" fill="url(#paint3_linear_6460_18289)" />
            <defs>
                <linearGradient id="paint0_linear_6460_18289" x1="9.00428" y1="1.84928" x2="9.00428" y2="18.0043" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#FEEA70" />
                    <stop offset="1" stop-color="#F69B30" />
                </linearGradient>
                <linearGradient id="paint1_linear_6460_18289" x1="9.00435" y1="9.00429" x2="9.00435" y2="15.7543" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#472315" />
                    <stop offset="1" stop-color="#8B3A0E" />
                </linearGradient>
                <linearGradient id="paint2_linear_6460_18289" x1="9.00428" y1="4.50413" x2="9.00428" y2="7.87913" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#191A33" />
                    <stop offset="0.872" stop-color="#3B426A" />
                </linearGradient>
                <linearGradient id="paint3_linear_6460_18289" x1="9.00445" y1="1.90331" x2="9.00445" y2="3.2525" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#E78E0D" />
                    <stop offset="1" stop-color="#CB6000" />
                </linearGradient>
            </defs>
        </svg>
    )
}

export default FbWow;