import React from 'react';

const SocialMediaIcon = (props) => {
  return (
    <svg width="22" height="20" viewBox="0 0 22 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
<path d="M15.8445 17.7539C15.8445 17.9664 15.6746 18.115 15.4834 18.115H3.01328C2.80078 18.115 2.65215 17.9451 2.65215 17.7539V6.68587H10.1512L14.9311 1.90601H3.03454C1.86609 1.90601 0.910156 2.86194 0.910156 4.03039V17.7327C0.910156 18.9011 1.86609 19.8571 3.03454 19.8571H15.4834C16.6519 19.8571 17.6078 18.9011 17.6078 17.7327V8.04548L15.8446 9.8086L15.8445 17.7539Z" fill="#181818"/>
<path d="M7.58022 13.2077C7.53769 13.4626 7.77138 13.6963 8.04758 13.6539L10.5543 13.3353C10.958 13.2928 11.3191 13.1016 11.5953 12.8254L11.7227 12.698L8.55745 9.51123L8.43001 9.63882C8.15379 9.91503 7.96265 10.2973 7.92012 10.6798L7.58022 13.2077Z" fill="#181818"/>
<path d="M20.7941 2.22471L19.0097 0.461469C18.8185 0.270308 18.5635 0.164062 18.3086 0.164062C18.0538 0.164062 17.7988 0.248973 17.6289 0.461469L9.17383 8.91652L12.3392 12.0819L20.7942 3.62684C21.1554 3.24437 21.1554 2.60702 20.7942 2.22471H20.7941Z" fill="#181818"/>
</svg>

  );
};

export default SocialMediaIcon;
