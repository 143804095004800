
export const setOfColors = [
    '#f5deb3',
    '#B9D9EB',
    '#F4C2C2',
    '#CCE5FF',
    'rgba(255, 143, 107, 0.3)',
    '#A8C3BC',
    '#F8F3EC',
    'rgba(158, 36, 46, 0.2)',
    'rgba(91, 147, 255, 0.3)',
    'rgba(24, 24, 24, 0.1)',
    '#F4F4F5',
    'rgba(35, 179, 58, 0.3)',
]