import React from 'react';

const MessageIcon = () => {
  return (
    <svg
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="15" cy="15" r="15" fill="#4AACEA" />
      <path
        d="M16.5 14.5C16.5 15.3284 15.8284 16 15 16C14.1716 16 13.5 15.3284 13.5 14.5C13.5 13.6716 14.1716 13 15 13C15.8284 13 16.5 13.6716 16.5 14.5Z"
        fill="white"
      />
      <path
        d="M21 14.5C21 15.3284 20.3284 16 19.5 16C18.6716 16 18 15.3284 18 14.5C18 13.6716 18.6716 13 19.5 13C20.3284 13 21 13.6716 21 14.5Z"
        fill="white"
      />
      <path
        d="M12 14.5C12 15.3284 11.3284 16 10.5 16C9.67157 16 9 15.3284 9 14.5C9 13.6716 9.67157 13 10.5 13C11.3284 13 12 13.6716 12 14.5Z"
        fill="white"
      />
      <path
        d="M24 19C24 19.5304 23.7893 20.0391 23.4142 20.4142C23.0391 20.7893 22.5304 21 22 21H10L6 24V10C6 9.46957 6.21071 8.96086 6.58579 8.58579C6.96086 8.21071 7.46957 8 8 8H22C22.5304 8 23.0391 8.21071 23.4142 8.58579C23.7893 8.96086 24 9.46957 24 10V19Z"
        stroke="white"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export default MessageIcon;
