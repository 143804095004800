import React from 'react';

const NoListFound = () => {
    return (
        <div>
            <svg width="195" height="166" viewBox="0 0 195 166" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M86.6016 160.8C121.948 160.8 150.602 132.147 150.602 96.8003C150.602 61.4541 121.948 32.8003 86.6016 32.8003C51.2553 32.8003 22.6016 61.4541 22.6016 96.8003C22.6016 132.147 51.2553 160.8 86.6016 160.8Z" fill="url(#paint0_linear_6910_19136)" />
                <path d="M138.176 40.9307H34.3302C30.969 40.9307 28.2441 43.6555 28.2441 47.0168V148.937C28.2441 152.299 30.969 155.023 34.3302 155.023H138.176C141.537 155.023 144.262 152.299 144.262 148.937V47.0168C144.262 43.6555 141.537 40.9307 138.176 40.9307Z" fill="white" />
                <path d="M32.3896 40.9307H140.757C142.87 40.9307 144.586 42.6469 144.586 44.7597V86.0139H28.5605V44.7597C28.5605 42.6469 30.2768 40.9307 32.3896 40.9307Z" fill="#5A6B93" />
                <path d="M89.5153 51.3218H41.3313C40.2401 51.3218 39.3555 52.2064 39.3555 53.2976C39.3555 54.3888 40.2401 55.2734 41.3313 55.2734H89.5153C90.6065 55.2734 91.4911 54.3888 91.4911 53.2976C91.4911 52.2064 90.6065 51.3218 89.5153 51.3218Z" fill="white" />
                <path d="M69.0576 61.4966H41.3313C40.2401 61.4966 39.3555 62.3812 39.3555 63.4724C39.3555 64.5636 40.2401 65.4482 41.3313 65.4482H69.0576C70.1489 65.4482 71.0335 64.5636 71.0335 63.4724C71.0335 62.3812 70.1489 61.4966 69.0576 61.4966Z" fill="white" />
                <path d="M58.8757 71.6714H41.3313C40.2401 71.6714 39.3555 72.556 39.3555 73.6472C39.3555 74.7384 40.2401 75.623 41.3313 75.623H58.8757C59.9669 75.623 60.8515 74.7384 60.8515 73.6472C60.8515 72.556 59.9669 71.6714 58.8757 71.6714Z" fill="white" />
                <path d="M90.8201 128.054H82.0082C80.459 128.054 79.2031 129.31 79.2031 130.859C79.2031 132.408 80.459 133.664 82.0082 133.664H90.8201C92.3693 133.664 93.6252 132.408 93.6252 130.859C93.6252 129.31 92.3693 128.054 90.8201 128.054Z" fill="#9298AA" />
                <path d="M104.927 109.306C104.927 107.756 103.671 106.5 102.121 106.5C100.572 106.5 99.3164 107.756 99.3164 109.306C99.3164 110.855 100.572 112.111 102.121 112.111C103.671 112.111 104.927 110.855 104.927 109.306Z" fill="#9298AA" />
                <path d="M73.5145 109.306C73.5145 107.756 72.2586 106.5 70.7094 106.5C69.1602 106.5 67.9043 107.756 67.9043 109.306C67.9043 110.855 69.1602 112.111 70.7094 112.111C72.2586 112.111 73.5145 110.855 73.5145 109.306Z" fill="#9298AA" />
                <path d="M147.11 3.07782C147.11 1.57242 145.889 0.352051 144.384 0.352051C142.879 0.352051 141.658 1.57242 141.658 3.07782V26.391C141.658 27.8964 142.879 29.1168 144.384 29.1168C145.889 29.1168 147.11 27.8964 147.11 26.391V3.07782Z" fill="#DFE2E8" />
                <path d="M179.957 14.6728C181.021 13.6084 181.021 11.8825 179.957 10.818C178.892 9.75355 177.166 9.75355 176.102 10.818L159.617 27.303C158.552 28.3674 158.552 30.0933 159.617 31.1578C160.681 32.2223 162.407 32.2223 163.472 31.1578L179.957 14.6728Z" fill="#DFE2E8" />
                <path d="M188.091 48.9471C189.596 48.9471 190.816 47.7268 190.816 46.2214C190.816 44.716 189.596 43.4956 188.091 43.4956H164.777C163.272 43.4956 162.052 44.716 162.052 46.2214C162.052 47.7268 163.272 48.9471 164.777 48.9471H188.091Z" fill="#DFE2E8" />
                <path d="M4.7644 53.2755C5.63694 53.2755 6.34362 53.9822 6.34362 54.8547C6.34362 55.7273 5.63694 56.4339 4.7644 56.4339C3.89187 56.4339 3.18519 55.7273 3.18519 54.8547C3.18519 53.9822 3.89187 53.2755 4.7644 53.2755ZM4.7644 50.3911C2.29823 50.3911 0.300781 52.3886 0.300781 54.8547C0.300781 57.3209 2.29823 59.3184 4.7644 59.3184C7.23057 59.3184 9.22802 57.3209 9.22802 54.8547C9.22802 52.3886 7.23057 50.3911 4.7644 50.3911Z" fill="#4AACEA" />
                <path d="M6.37378 132.756C7.24631 132.756 7.95299 133.462 7.95299 134.335C7.95299 135.207 7.24631 135.914 6.37378 135.914C5.50124 135.914 4.79456 135.207 4.79456 134.335C4.79456 133.462 5.50124 132.756 6.37378 132.756ZM6.37378 129.871C3.90761 129.871 1.91016 131.869 1.91016 134.335C1.91016 136.801 3.90761 138.798 6.37378 138.798C8.83995 138.798 10.8374 136.801 10.8374 134.335C10.8374 131.869 8.83995 129.871 6.37378 129.871Z" fill="#4AACEA" />
                <path d="M166.932 159.357C167.805 159.357 168.512 160.064 168.512 160.936C168.512 161.809 167.805 162.515 166.932 162.515C166.06 162.515 165.353 161.809 165.353 160.936C165.353 160.064 166.06 159.357 166.932 159.357ZM166.932 156.473C164.466 156.473 162.469 158.47 162.469 160.936C162.469 163.402 164.466 165.4 166.932 165.4C169.399 165.4 171.396 163.402 171.396 160.936C171.396 158.47 169.399 156.473 166.932 156.473Z" fill="#4AACEA" />
                <path d="M22.1926 14.8938L16.4238 9.125" stroke="#7AA9E6" stroke-width="4" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M22.1927 9.125L16.4238 14.8938" stroke="#7AA9E6" stroke-width="4" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M189.052 129.554L183.283 123.786" stroke="#3771C8" stroke-width="4" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M189.052 123.786L183.283 129.554" stroke="#3771C8" stroke-width="4" stroke-linecap="round" stroke-linejoin="round" />
                <defs>
                    <linearGradient id="paint0_linear_6910_19136" x1="86.2945" y1="31.4662" x2="86.8927" y2="159.461" gradientUnits="userSpaceOnUse">
                        <stop stop-color="#EBF0FF" />
                        <stop offset="1" stop-color="#F4F8FF" />
                    </linearGradient>
                </defs>
            </svg>

        </div>
    );
};

export default NoListFound;
