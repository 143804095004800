import React from 'react'

const InstComment = () => {
    return (
        <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="15" cy="15" r="15" fill="#01C5C4" fill-opacity="0.1" />
            <path d="M21.75 15C21.75 18.7279 18.7279 21.75 15 21.75C13.6717 21.75 12.433 21.3663 11.3887 20.7038L8.25 21.75L9.29624 18.6113C8.63367 17.567 8.25 16.3283 8.25 15C8.25 11.2721 11.2721 8.25 15 8.25C18.7279 8.25 21.75 11.2721 21.75 15Z" fill="#01C5C4" stroke="#01C5C4" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M16.125 15C16.125 15.6213 15.6213 16.125 15 16.125C14.3787 16.125 13.875 15.6213 13.875 15C13.875 14.3787 14.3787 13.875 15 13.875C15.6213 13.875 16.125 14.3787 16.125 15Z" fill="white" />
            <path d="M19.5 15C19.5 15.6213 18.9963 16.125 18.375 16.125C17.7537 16.125 17.25 15.6213 17.25 15C17.25 14.3787 17.7537 13.875 18.375 13.875C18.9963 13.875 19.5 14.3787 19.5 15Z" fill="white" />
            <path d="M12.75 15C12.75 15.6213 12.2463 16.125 11.625 16.125C11.0037 16.125 10.5 15.6213 10.5 15C10.5 14.3787 11.0037 13.875 11.625 13.875C12.2463 13.875 12.75 14.3787 12.75 15Z" fill="white" />
        </svg>
    )
}

export default InstComment