
export const prospectColors = [
    'RGB(146, 168, 209)',
    'RGB(247, 202, 201)',
    'RGB(223, 207, 190)',
    '#A0DAA9',
    '#7FCDCD',
    'rgba(0, 114, 181, 0.7)',
    'rgba(146, 106, 166, 0.7)',
    'rgba(0, 161, 112, 0.7)',
    'rgba(147, 149, 151, 0.5)',
    '#EFE1CE',
]