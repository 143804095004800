import React from 'react'

const FbAdds = () => {
  return (
   <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
   <g clip-path="url(#clip0_8945_32282)">
   <path d="M22.5 1.49968C22.5 0.894992 22.1344 0.346555 21.5719 0.11218C21.0094 -0.122195 20.3672 0.00905482 19.9359 0.435617L17.8922 2.48405C15.6422 4.73405 12.5906 5.99968 9.40781 5.99968H9H7.5H3C1.34531 5.99968 0 7.34499 0 8.99968V13.4997C0 15.1544 1.34531 16.4997 3 16.4997V22.4997C3 23.3294 3.67031 23.9997 4.5 23.9997H7.5C8.32969 23.9997 9 23.3294 9 22.4997V16.4997H9.40781C12.5906 16.4997 15.6422 17.7653 17.8922 20.0153L19.9359 22.0591C20.3672 22.4903 21.0094 22.6169 21.5719 22.3825C22.1344 22.1481 22.5 21.6044 22.5 20.995V14.0809C23.3719 13.6684 24 12.5575 24 11.2497C24 9.94187 23.3719 8.83093 22.5 8.41843V1.49968ZM19.5 5.09499V11.2497V17.4044C16.7437 14.8966 13.1484 13.4997 9.40781 13.4997H9V8.99968H9.40781C13.1484 8.99968 16.7437 7.6028 19.5 5.09499Z" fill="#0062E0"/>
   </g>
   <defs>
   <clipPath id="clip0_8945_32282">
   <rect width="24" height="24" fill="white"/>
   </clipPath>
   </defs>
   </svg>
   
  )
}

export default FbAdds