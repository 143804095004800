import React from "react";

const BlueCalenderIcon = props => {
    return (
        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path d="M3 6H15" stroke="#003E66" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M12 2.25V3.75" stroke="#003E66" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M6 2.25V3.75" stroke="#003E66" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M2.25 4.5C2.25 3.67157 2.92157 3 3.75 3H14.25C15.0784 3 15.75 3.67157 15.75 4.5V14.25C15.75 15.0784 15.0784 15.75 14.25 15.75H3.75C2.92157 15.75 2.25 15.0784 2.25 14.25V4.5Z" stroke="#003E66" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M12 12.375H12.0015V12.3765H12V12.375Z" stroke="#003E66" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M9 12.375H9.0015V12.3765H9V12.375Z" stroke="#003E66" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M6 12.375H6.0015V12.3765H6V12.375Z" stroke="#003E66" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M12 9.375H12.0015V9.3765H12V9.375Z" stroke="#003E66" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M9 9.375H9.0015V9.3765H9V9.375Z" stroke="#003E66" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M6 9.375H6.0015V9.3765H6V9.375Z" stroke="#003E66" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
    )
}

export default BlueCalenderIcon;