import React from "react";

const BlueTagIcon = props => {
    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path d="M8.49036 14.6668C7.94505 14.6691 7.42108 14.4551 7.03332 14.0716L1.9279 8.9662C1.72251 8.75677 1.56367 8.50633 1.46177 8.23127C1.35987 7.9562 1.31721 7.66272 1.33658 7.37003L1.55687 3.81439C1.59823 3.22945 1.84931 2.67908 2.26396 2.26442C2.67861 1.84977 3.22898 1.5987 3.81393 1.55734L7.36956 1.33705C7.66214 1.31889 7.95528 1.36211 8.23017 1.46395C8.50505 1.56578 8.7556 1.72397 8.96573 1.92836L14.0712 7.03379C14.472 7.46862 14.6843 8.04441 14.6619 8.63535C14.6394 9.22629 14.384 9.78432 13.9514 10.1875L10.1754 13.9518C9.95562 14.1763 9.69356 14.355 9.40435 14.4777C9.11515 14.6004 8.80452 14.6647 8.49036 14.6668ZM7.5087 2.29939H7.41981L3.86031 2.51582C3.51124 2.54479 3.18377 2.69661 2.93609 2.94429C2.68842 3.19196 2.5366 3.51944 2.50762 3.8685L2.30278 7.42027C2.28893 7.57833 2.30938 7.73751 2.36275 7.88693C2.41611 8.03634 2.50112 8.17247 2.61197 8.28599L7.7058 13.3876C7.81748 13.4955 7.95039 13.5791 8.0961 13.633C8.24181 13.6869 8.39712 13.7099 8.5522 13.7006C8.90838 13.6789 9.24348 13.5244 9.49135 13.2677L13.2711 9.49181C13.5209 9.26896 13.6731 8.95683 13.6948 8.62278C13.7165 8.28874 13.606 7.95956 13.3871 7.70627L8.29325 2.61244C8.19002 2.5105 8.06741 2.43027 7.93266 2.37651C7.79791 2.32274 7.65375 2.29652 7.5087 2.29939Z" fill="#3771C8" stroke="#3771C8" stroke-width="0.5" />
            <path d="M5.67184 7.31619C5.23621 7.31619 4.81843 7.14314 4.51039 6.8351C4.20235 6.52706 4.0293 6.10927 4.0293 5.67364C4.02933 5.34857 4.12582 5.03081 4.30655 4.76061C4.48727 4.4904 4.74412 4.2799 5.04455 4.15575C5.34499 4.0316 5.67551 3.9994 5.99426 4.0632C6.31301 4.12701 6.60566 4.28397 6.83516 4.5142C7.06406 4.7439 7.21985 5.03621 7.2829 5.3543C7.34595 5.6724 7.31343 6.00204 7.18944 6.30168C7.06545 6.60132 6.85554 6.85756 6.58616 7.0381C6.31678 7.21864 5.99999 7.3154 5.67571 7.31619H5.67184ZM5.67184 4.9973C5.53699 4.99612 5.40485 5.03528 5.29242 5.10976C5.17999 5.18424 5.09239 5.29063 5.04087 5.41526C4.98935 5.5399 4.97627 5.67709 5.00331 5.80922C5.03035 5.94134 5.09627 6.06237 5.19261 6.15675C5.32191 6.27963 5.49347 6.34815 5.67184 6.34815C5.85022 6.34815 6.02178 6.27963 6.15108 6.15675C6.27758 6.02933 6.34857 5.85706 6.34857 5.67751C6.34857 5.49796 6.27758 5.32569 6.15108 5.19827C6.08853 5.13466 6.01394 5.08413 5.93166 5.04962C5.84938 5.01512 5.76106 4.99733 5.67184 4.9973Z" fill="#3771C8" stroke="#3771C8" stroke-width="0.5" />
        </svg>
    )
}

export default BlueTagIcon;