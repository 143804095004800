import React from 'react';

const WhatsAppIcon = props => {
  return (
    <svg
      width={22}
      height={20}
      viewBox="0 0 22 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.0861 2.90644C15.206 1.0332 12.7056 0.00105288 10.0418 0C4.55293 0 0.085694 4.44554 0.0834974 9.90983C0.0827652 11.6565 0.541337 13.3615 1.41273 14.8644L0 20L5.27902 18.6219C6.73349 19.4114 8.37111 19.8275 10.0378 19.8282H10.0419C15.5301 19.8282 19.9978 15.3821 20 9.91774C20.0011 7.26966 18.9662 4.77968 17.0861 2.90644ZM10.0419 18.1543H10.0385C8.55332 18.1538 7.0966 17.7567 5.82584 17.0062L5.52357 16.8276L2.39097 17.6455L3.22714 14.6058L3.03031 14.2942C2.20179 12.9827 1.76418 11.4668 1.76484 9.91045C1.76665 5.36875 5.47967 1.67381 10.0452 1.67381C12.2559 1.67465 14.334 2.53259 15.8967 4.08955C17.4594 5.64652 18.3195 7.71611 18.3187 9.91709C18.3168 14.4591 14.6038 18.1543 10.0419 18.1543ZM14.5819 11.9851C14.3331 11.8612 13.1097 11.2621 12.8816 11.1795C12.6536 11.0968 12.4877 11.0555 12.3218 11.3034C12.156 11.5513 11.6791 12.1091 11.5339 12.2744C11.3888 12.4396 11.2437 12.4603 10.9948 12.3363C10.746 12.2124 9.9443 11.9509 8.99388 11.1073C8.25423 10.4507 7.75482 9.63973 7.60968 9.39184C7.46454 9.14393 7.59425 9.00989 7.7188 8.88646C7.83072 8.7755 7.96762 8.59724 8.09203 8.45261C8.21643 8.30802 8.25789 8.20473 8.34082 8.03948C8.42377 7.87421 8.38231 7.72958 8.3201 7.60564C8.25789 7.4817 7.7603 6.26284 7.55292 5.76704C7.35097 5.28417 7.14582 5.3495 6.99309 5.34194C6.84811 5.33476 6.68207 5.33322 6.51621 5.33322C6.35033 5.33322 6.08077 5.39521 5.85268 5.64309C5.62462 5.89101 4.98186 6.4901 4.98186 7.70893C4.98186 8.92776 5.87343 10.1053 5.99785 10.2705C6.12224 10.4358 7.75238 12.9371 10.2484 14.0097C10.8421 14.2648 11.3055 14.4171 11.6669 14.5313C12.263 14.7197 12.8054 14.6932 13.2341 14.6294C13.7122 14.5583 14.7063 14.0304 14.9136 13.4519C15.121 12.8735 15.121 12.3777 15.0588 12.2744C14.9966 12.1711 14.8307 12.1091 14.5819 11.9851Z"
        fill="#181818"
      />
    </svg>
  );
};

export default WhatsAppIcon;
