import React from 'react'

const EmptyInbox = () => {
  return (
   <svg width="293" height="278" viewBox="0 0 293 278" fill="none" xmlns="http://www.w3.org/2000/svg">
   <g filter="url(#filter0_d_6271_18002)">
   <path d="M132.967 239.969C193.149 239.969 241.935 191.183 241.935 131.002C241.935 70.8206 193.149 22.0342 132.967 22.0342C72.7864 22.0342 24 70.8206 24 131.002C24 191.183 72.7864 239.969 132.967 239.969Z" fill="url(#paint0_linear_6271_18002)"/>
   <path d="M197.47 61.1631H67.6623C63.4607 61.1631 60.0547 64.5691 60.0547 68.7707V196.171C60.0547 200.373 63.4607 203.779 67.6623 203.779H197.47C201.671 203.779 205.077 200.373 205.077 196.171V68.7707C205.077 64.5691 201.671 61.1631 197.47 61.1631Z" fill="white"/>
   <path d="M65.2375 61.1631H200.696C203.337 61.1631 205.483 63.3084 205.483 65.9494V117.517H60.4512V65.9494C60.4512 63.3084 62.5965 61.1631 65.2375 61.1631Z" fill="#5A6B93"/>
   <path d="M136.643 74.1521H76.4131C75.0491 74.1521 73.9434 75.2579 73.9434 76.6219C73.9434 77.9859 75.0491 79.0916 76.4131 79.0916H136.643C138.007 79.0916 139.113 77.9859 139.113 76.6219C139.113 75.2579 138.007 74.1521 136.643 74.1521Z" fill="white"/>
   <path d="M111.071 86.8706H76.4131C75.0491 86.8706 73.9434 87.9764 73.9434 89.3404C73.9434 90.7044 75.0491 91.8101 76.4131 91.8101H111.071C112.435 91.8101 113.541 90.7044 113.541 89.3404C113.541 87.9764 112.435 86.8706 111.071 86.8706Z" fill="white"/>
   <path d="M98.3436 99.5891H76.4131C75.0491 99.5891 73.9434 100.695 73.9434 102.059C73.9434 103.423 75.0491 104.529 76.4131 104.529H98.3436C99.7077 104.529 100.813 103.423 100.813 102.059C100.813 100.695 99.7077 99.5891 98.3436 99.5891Z" fill="white"/>
   <path d="M138.273 170.067H127.258C125.322 170.067 123.752 171.637 123.752 173.574C123.752 175.51 125.322 177.08 127.258 177.08H138.273C140.21 177.08 141.779 175.51 141.779 173.574C141.779 171.637 140.21 170.067 138.273 170.067Z" fill="#9298AA"/>
   <path d="M155.905 146.632C155.905 144.695 154.335 143.125 152.399 143.125C150.462 143.125 148.893 144.695 148.893 146.632C148.893 148.568 150.462 150.138 152.399 150.138C154.335 150.138 155.905 148.568 155.905 146.632Z" fill="#9298AA"/>
   <path d="M116.642 146.632C116.642 144.695 115.072 143.125 113.135 143.125C111.199 143.125 109.629 144.695 109.629 146.632C109.629 148.568 111.199 150.138 113.135 150.138C115.072 150.138 116.642 148.568 116.642 146.632Z" fill="#9298AA"/>
   <path d="M208.637 13.8471C208.637 11.9654 207.111 10.4399 205.229 10.4399C203.348 10.4399 201.822 11.9654 201.822 13.8471V42.9887C201.822 44.8704 203.348 46.3959 205.229 46.3959C207.111 46.3959 208.637 44.8704 208.637 42.9887V13.8471Z" fill="#DFE2E8"/>
   <path d="M249.696 28.3411C251.027 27.0105 251.027 24.8531 249.696 23.5225C248.366 22.1919 246.208 22.1919 244.878 23.5225L224.272 44.1287C222.941 45.4593 222.941 47.6166 224.272 48.9472C225.602 50.2778 227.76 50.2778 229.09 48.9472L249.696 28.3411Z" fill="#DFE2E8"/>
   <path d="M259.862 71.1841C261.744 71.1841 263.27 69.6586 263.27 67.7768C263.27 65.8951 261.744 64.3696 259.862 64.3696H230.721C228.839 64.3696 227.314 65.8951 227.314 67.7768C227.314 69.6586 228.839 71.1841 230.721 71.1841H259.862Z" fill="#DFE2E8"/>
   <path d="M30.7045 76.5945C31.7952 76.5945 32.6785 77.4779 32.6785 78.5685C32.6785 79.6592 31.7952 80.5426 30.7045 80.5426C29.6139 80.5426 28.7305 79.6592 28.7305 78.5685C28.7305 77.4779 29.6139 76.5945 30.7045 76.5945ZM30.7045 72.989C27.6218 72.989 25.125 75.4858 25.125 78.5685C25.125 81.6512 27.6218 84.1481 30.7045 84.1481C33.7872 84.1481 36.284 81.6512 36.284 78.5685C36.284 75.4858 33.7872 72.989 30.7045 72.989Z" fill="#4AACEA"/>
   <path d="M32.7162 175.944C33.8069 175.944 34.6903 176.828 34.6903 177.918C34.6903 179.009 33.8069 179.892 32.7162 179.892C31.6256 179.892 30.7422 179.009 30.7422 177.918C30.7422 176.828 31.6256 175.944 32.7162 175.944ZM32.7162 172.339C29.6335 172.339 27.1367 174.836 27.1367 177.918C27.1367 181.001 29.6335 183.498 32.7162 183.498C35.799 183.498 38.2958 181.001 38.2958 177.918C38.2958 174.836 35.799 172.339 32.7162 172.339Z" fill="#4AACEA"/>
   <path d="M233.415 209.196C234.506 209.196 235.389 210.079 235.389 211.17C235.389 212.261 234.506 213.144 233.415 213.144C232.325 213.144 231.441 212.261 231.441 211.17C231.441 210.079 232.325 209.196 233.415 209.196ZM233.415 205.591C230.333 205.591 227.836 208.087 227.836 211.17C227.836 214.253 230.333 216.75 233.415 216.75C236.498 216.75 238.995 214.253 238.995 211.17C238.995 208.087 236.498 205.591 233.415 205.591Z" fill="#4AACEA"/>
   <path d="M52.4883 28.6165L45.2773 21.4055" stroke="#7AA9E6" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/>
   <path d="M52.4884 21.4055L45.2773 28.6166" stroke="#7AA9E6" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/>
   <path d="M261.063 171.942L253.852 164.731" stroke="#3771C8" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/>
   <path d="M261.063 164.731L253.852 171.942" stroke="#3771C8" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/>
   </g>
   <defs>
   <filter id="filter0_d_6271_18002" x="-3" y="-10" width="298" height="298" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
   <feFlood flood-opacity="0" result="BackgroundImageFix"/>
   <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
   <feOffset dy="14"/>
   <feGaussianBlur stdDeviation="12"/>
   <feComposite in2="hardAlpha" operator="out"/>
   <feColorMatrix type="matrix" values="0 0 0 0 0.309804 0 0 0 0 0.360784 0 0 0 0 0.501961 0 0 0 0.15 0"/>
   <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_6271_18002"/>
   <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_6271_18002" result="shape"/>
   </filter>
   <linearGradient id="paint0_linear_6271_18002" x1="132.445" y1="19.7627" x2="133.463" y2="237.689" gradientUnits="userSpaceOnUse">
   <stop stop-color="#EBF0FF"/>
   <stop offset="1" stop-color="#F4F8FF"/>
   </linearGradient>
   </defs>
   </svg>
   
  )
}

export default EmptyInbox