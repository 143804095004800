import React from 'react'

const NochatSelected = () => {
  return (
   <svg width="368" height="278" viewBox="0 0 368 278" fill="none" xmlns="http://www.w3.org/2000/svg">
   <path d="M23.9992 192.083H13.698C13.0202 192.083 12.4707 192.633 12.4707 193.311C12.4707 193.988 13.0202 194.538 13.698 194.538H23.9992C24.6771 194.538 25.2265 193.988 25.2265 193.311C25.2265 192.633 24.6771 192.083 23.9992 192.083Z" fill="#C4CED4"/>
   <path d="M17.6191 188.162V198.465C17.6191 199.143 18.1686 199.692 18.8465 199.692C19.5243 199.692 20.0738 199.143 20.0738 198.465V188.162C20.0738 187.484 19.5243 186.935 18.8465 186.935C18.1686 186.935 17.6191 187.484 17.6191 188.162Z" fill="#C4CED4"/>
   <path d="M366.773 81.1426H356.471C355.794 81.1426 355.244 81.6921 355.244 82.3701C355.244 83.048 355.794 83.5976 356.471 83.5976H366.773C367.451 83.5976 368 83.048 368 82.3701C368 81.6921 367.451 81.1426 366.773 81.1426Z" fill="#C4CED4"/>
   <path d="M360.393 77.2157V87.5186C360.393 88.1965 360.942 88.7461 361.62 88.7461C362.298 88.7461 362.847 88.1965 362.847 87.5186V77.2157C362.847 76.5378 362.298 75.9882 361.62 75.9882C360.942 75.9882 360.393 76.5378 360.393 77.2157Z" fill="#C4CED4"/>
   <path d="M159.812 17.5391H149.511C148.833 17.5391 148.283 18.0886 148.283 18.7666C148.283 19.4445 148.833 19.9941 149.511 19.9941H159.812C160.49 19.9941 161.039 19.4445 161.039 18.7666C161.039 18.0886 160.49 17.5391 159.812 17.5391Z" fill="#C4CED4"/>
   <path d="M153.432 13.6122V23.9151C153.432 24.593 153.981 25.1426 154.659 25.1426C155.337 25.1426 155.886 24.593 155.886 23.9151V13.6122C155.886 12.9343 155.337 12.3847 154.659 12.3847C153.981 12.3847 153.432 12.9343 153.432 13.6122Z" fill="#C4CED4"/>
   <path d="M335.362 203.957C339.111 203.957 342.15 200.917 342.15 197.167C342.15 193.418 339.111 190.378 335.362 190.378C331.613 190.378 328.574 193.418 328.574 197.167C328.574 200.917 331.613 203.957 335.362 203.957Z" fill="#C4CED4"/>
   <path d="M6.78799 118.119C10.5369 118.119 13.576 115.079 13.576 111.33C13.576 107.58 10.5369 104.541 6.78799 104.541C3.03909 104.541 0 107.58 0 111.33C0 115.079 3.03909 118.119 6.78799 118.119Z" fill="#C4CED4"/>
   <path d="M47.37 38.7207C51.1189 38.7207 54.158 35.6812 54.158 31.9316C54.158 28.1821 51.1189 25.1426 47.37 25.1426C43.6211 25.1426 40.582 28.1821 40.582 31.9316C40.582 35.6812 43.6211 38.7207 47.37 38.7207Z" stroke="#C4CED3" stroke-width="3" stroke-miterlimit="10"/>
   <path d="M260.649 15.4507C264.398 15.4507 267.437 12.4111 267.437 8.66163C267.437 4.91213 264.398 1.87256 260.649 1.87256C256.9 1.87256 253.861 4.91213 253.861 8.66163C253.861 12.4111 256.9 15.4507 260.649 15.4507Z" stroke="#C4CED3" stroke-width="3" stroke-miterlimit="10"/>
   <g filter="url(#filter0_d_6278_18375)">
   <path d="M287.841 64.3589H94.0022C82.8647 64.3589 73.8359 73.389 73.8359 84.5283V165.194C73.8359 176.334 82.8647 185.364 94.0022 185.364H287.841C298.978 185.364 308.007 176.334 308.007 165.194V84.5283C308.007 73.389 298.978 64.3589 287.841 64.3589Z" fill="#DFE2E8"/>
   <path d="M186.452 109.073H115.908C113.829 109.073 112.145 110.758 112.145 112.837C112.145 114.915 113.829 116.601 115.908 116.601H186.452C188.53 116.601 190.215 114.915 190.215 112.837C190.215 110.758 188.53 109.073 186.452 109.073Z" fill="#C4CED4"/>
   <path d="M265.948 133.117H115.908C113.829 133.117 112.145 134.802 112.145 136.881C112.145 138.959 113.829 140.645 115.908 140.645H265.948C268.026 140.645 269.711 138.959 269.711 136.881C269.711 134.802 268.026 133.117 265.948 133.117Z" fill="#C4CED4"/>
   <path d="M265.226 124.995H71.3869C60.2494 124.995 51.2207 134.025 51.2207 145.165V225.831C51.2207 236.97 60.2494 246 71.3869 246H265.226C276.363 246 285.392 236.97 285.392 225.831V145.165C285.392 134.025 276.363 124.995 265.226 124.995Z" fill="white"/>
   <path d="M163.831 169.709H93.2868C91.2083 169.709 89.5234 171.394 89.5234 173.473C89.5234 175.552 91.2083 177.237 93.2868 177.237H163.831C165.909 177.237 167.594 175.552 167.594 173.473C167.594 171.394 165.909 169.709 163.831 169.709Z" fill="#C4CED4"/>
   <path d="M243.326 193.759H93.2868C91.2083 193.759 89.5234 195.444 89.5234 197.523C89.5234 199.602 91.2083 201.287 93.2868 201.287H243.326C245.405 201.287 247.09 199.602 247.09 197.523C247.09 195.444 245.405 193.759 243.326 193.759Z" fill="#C4CED4"/>
   </g>
   <defs>
   <filter id="filter0_d_6278_18375" x="23.2207" y="48.3589" width="304.787" height="229.641" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
   <feFlood flood-opacity="0" result="BackgroundImageFix"/>
   <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
   <feOffset dx="-4" dy="8"/>
   <feGaussianBlur stdDeviation="12"/>
   <feComposite in2="hardAlpha" operator="out"/>
   <feColorMatrix type="matrix" values="0 0 0 0 0.309804 0 0 0 0 0.360784 0 0 0 0 0.501961 0 0 0 0.15 0"/>
   <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_6278_18375"/>
   <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_6278_18375" result="shape"/>
   </filter>
   </defs>
   </svg>
   
  )
}

export default NochatSelected