import * as React from 'react';

const TemplateFolderIcon = props => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path d="M19 3H5C3.89543 3 3 3.89543 3 5V19C3 20.1046 3.89543 21 5 21H19C20.1046 21 21 20.1046 21 19V5C21 3.89543 20.1046 3 19 3Z" stroke="#4D4D4D" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M3 9H21" stroke="#4D4D4D" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M9 21V9" stroke="#4D4D4D" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
    )
}

export default TemplateFolderIcon;