import React from 'react'

const Star = () => {
  return (
   <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
   <circle cx="12" cy="12" r="11.5" fill="white" stroke="#4D4D4D"/>
   <path d="M13.8233 9.829L12.2772 6.50905C12.1681 6.27475 11.8319 6.27475 11.7228 6.50905L10.1767 9.829C10.1322 9.92449 10.0408 9.99024 9.9354 10.0026L6.26971 10.4331C6.01102 10.4635 5.90712 10.7802 6.09838 10.9554L8.80852 13.4377C8.88647 13.5091 8.92137 13.6155 8.90068 13.7186L8.18127 17.3046C8.1305 17.5577 8.4025 17.7534 8.62982 17.6274L11.8509 15.8416C11.9435 15.7902 12.0565 15.7902 12.1491 15.8416L15.3702 17.6274C15.5975 17.7534 15.8695 17.5577 15.8187 17.3046L15.0993 13.7186C15.0786 13.6155 15.1135 13.5091 15.1915 13.4377L17.9016 10.9554C18.0929 10.7802 17.989 10.4635 17.7303 10.4331L14.0646 10.0026C13.9592 9.99024 13.8678 9.92449 13.8233 9.829Z" stroke="#4D4D4D" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
   </svg>
   
  )
}

export default Star