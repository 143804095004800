import React from 'react'

const MenuDots= () => {
  return (
<svg width="16" height="4" viewBox="0 0 16 4" fill="none" xmlns="http://www.w3.org/2000/svg">
<circle cx="2" cy="2" r="2" fill="#65676B"/>
<circle cx="8" cy="2" r="2" fill="#65676B"/>
<circle cx="14" cy="2" r="2" fill="#65676B"/>
</svg>
  )
}

export default MenuDots