import * as React from 'react';

const WhatsappIcon = props => {
    return (
        <svg width="27" height="26" viewBox="0 0 27 26" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path fillRule="evenodd" clipRule="evenodd" d="M22.8246 3.74198C20.3142 1.3308 16.9755 0.00357742 13.414 0C6.07568 0 0.111412 5.72029 0.111412 12.7535C0.111412 15.0001 0.72418 17.1966 1.88658 19.1284L0 25.7359L7.0524 23.9615C8.99469 24.9775 11.1821 25.5142 13.414 25.5142C20.7487 25.5142 26.713 19.7938 26.7167 12.7606C26.7167 9.35136 25.3352 6.14957 22.8246 3.74198ZM13.3509 23.4749C11.3678 23.4749 9.42176 22.9634 7.72459 21.9975L7.31978 21.7686L3.1344 22.8203L4.25595 18.9066L3.99227 18.506C2.88558 16.8174 2.2988 14.8678 2.30252 12.8644C2.30252 7.01888 7.26408 2.26451 13.362 2.26451C16.3145 2.26451 19.0886 3.36992 21.1758 5.37328C23.2629 7.37662 24.4141 10.0418 24.4104 12.8715C24.4068 18.717 19.4489 23.4749 13.3509 23.4749ZM8.65674 6.86148C8.87585 6.86148 9.09867 6.86148 9.29178 6.86863C9.52947 6.87578 9.79314 6.8901 10.042 7.41956C10.3353 8.04918 10.9815 9.62324 11.0521 9.78064C11.1338 9.9452 11.1895 10.1277 11.0781 10.3423C10.9667 10.5569 10.911 10.6893 10.7476 10.8753C10.5805 11.0649 10.3985 11.2939 10.2499 11.437C10.0828 11.5944 9.90827 11.7661 10.1014 12.0845C10.2945 12.4029 10.963 13.4439 11.9508 14.2918C13.2209 15.3828 14.2904 15.7156 14.6248 15.8765C14.9589 16.0339 15.1521 16.0089 15.3451 15.7978C15.5382 15.5868 16.1771 14.8678 16.3999 14.5493C16.6265 14.2309 16.8456 14.2846 17.1501 14.3919C17.4546 14.4956 19.0924 15.2684 19.4229 15.4294C19.7571 15.5868 19.9799 15.669 20.0616 15.8014C20.1433 15.9374 20.1433 16.5742 19.8685 17.3182C19.5937 18.0588 18.2308 18.7778 17.6217 18.8314C17.0164 18.8852 16.4408 19.0963 13.6331 18.0337C10.2574 16.753 8.12195 13.4332 7.95484 13.2222C7.78772 13.011 6.59933 11.4979 6.59933 9.92732C6.59933 8.35684 7.4609 7.58412 7.76173 7.26573C8.07739 6.94018 8.43392 6.86148 8.65674 6.86148Z" fill="#21D366" />
        </svg>
    );
};

export default WhatsappIcon;