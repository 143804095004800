import * as React from 'react';

const SecurityShieldIcon = props => {
    return (
        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path d="M7.86663 1.67254L4.12414 3.07504C3.26164 3.39754 2.55664 4.41753 2.55664 5.34003V10.9125C2.55664 11.7975 3.14165 12.96 3.85415 13.4925L7.07914 15.9C8.13664 16.695 9.87663 16.695 10.9341 15.9L14.1591 13.4925C14.8716 12.96 15.4566 11.7975 15.4566 10.9125V5.34003C15.4566 4.41753 14.7516 3.39754 13.8891 3.07504L10.1466 1.67254C9.50914 1.44004 8.48913 1.44004 7.86663 1.67254Z" stroke="#666666" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M8.99977 8.19006C8.96977 8.19006 8.93227 8.19006 8.90227 8.19006C8.19727 8.16756 7.63477 7.58256 7.63477 6.87006C7.63477 6.14256 8.22727 5.55005 8.95477 5.55005C9.68227 5.55005 10.2748 6.14256 10.2748 6.87006C10.2673 7.59006 9.70477 8.16756 8.99977 8.19006Z" stroke="#666666" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M7.5068 10.2898C6.7868 10.7698 6.7868 11.5573 7.5068 12.0373C8.3243 12.5848 9.6668 12.5848 10.4843 12.0373C11.2043 11.5573 11.2043 10.7698 10.4843 10.2898C9.6743 9.74228 8.3318 9.74228 7.5068 10.2898Z" stroke="#666666" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
    )
}

export default SecurityShieldIcon;