import React from 'react'

const Published = () => {
  return (
    <svg width="70" height="70" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="50" height="50" rx="10" fill="#00AC4F" fill-opacity="0.1"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M33.0648 20.7302C33.0648 21.1308 32.7397 21.4558 32.3392 21.4558C31.9387 21.4558 31.6136 21.1308 31.6136 20.7302V17.8278C31.6136 16.6252 30.6394 15.651 29.4368 15.651H17.8272C16.6246 15.651 15.6504 16.6252 15.6504 17.8278V29.4374C15.6504 30.64 16.6246 31.6142 17.8272 31.6142H20.7296C21.1302 31.6142 21.4552 31.9393 21.4552 32.3398C21.4552 32.7403 21.1302 33.0654 20.7296 33.0654H17.8272C15.8236 33.0654 14.1992 31.4411 14.1992 29.4374V17.8278C14.1992 15.8242 15.8236 14.1998 17.8272 14.1998H29.4368C31.4404 14.1998 33.0648 15.8242 33.0648 17.8278V20.7302Z" fill="#00AC4F" stroke="#00AC4F" stroke-width="0.5"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M28.4698 21.9391C32.0737 21.9391 35.0002 24.8656 35.0002 28.4695C35.0002 32.0733 32.0737 34.9999 28.4698 34.9999C24.866 34.9999 21.9395 32.0733 21.9395 28.4695C21.9395 24.8656 24.866 21.9391 28.4698 21.9391ZM28.4698 23.3903C25.6661 23.3903 23.3907 25.6657 23.3907 28.4695C23.3907 31.2732 25.6661 33.5487 28.4698 33.5487C31.2736 33.5487 33.549 31.2732 33.549 28.4695C33.549 25.6657 31.2736 23.3903 28.4698 23.3903Z" fill="#00AC4F" stroke="#00AC4F" stroke-width="0.5"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M27.7441 25.5673C27.7441 25.1667 28.0692 24.8417 28.4697 24.8417C28.8703 24.8417 29.1953 25.1667 29.1953 25.5673V28.1687L30.9175 29.8919C31.2009 30.1744 31.2009 30.635 30.9175 30.9174C30.635 31.2009 30.1744 31.2009 29.892 30.9174L27.957 28.9824C27.8206 28.847 27.7441 28.6622 27.7441 28.4697L27.7441 25.5673Z" fill="#00AC4F" stroke="#00AC4F" stroke-width="0.5"/>
    </svg>
    
  )
}

export default Published