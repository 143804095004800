// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.edgebutton {
    width: 30px;
    height: 30px;
    background: #eee;
    border: 1px solid rgb(200, 91, 91);
    cursor: pointer;
    border-radius: 50%;
    font-size: 20px;
    line-height: 1;
  }
  
  .edgebutton:hover {
    box-shadow: 0 0 6px 2px rgba(0, 0, 0, 0.08);
  }
  
  .edgebutton-foreignobject div {
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 40px;
  }

  .custom-file-upload{
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
  }`, "",{"version":3,"sources":["webpack://./src/modules/CreateSurvey/components/partials/buttonEdge.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,YAAY;IACZ,gBAAgB;IAChB,kCAAkC;IAClC,eAAe;IACf,kBAAkB;IAClB,eAAe;IACf,cAAc;EAChB;;EAEA;IACE,2CAA2C;EAC7C;;EAEA;IACE,WAAW;IACX,YAAY;IACZ,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,gBAAgB;EAClB;;EAEA;IACE,WAAW;IACX,YAAY;IACZ,aAAa;IACb,mBAAmB;IACnB,6BAA6B;EAC/B","sourcesContent":[".edgebutton {\n    width: 30px;\n    height: 30px;\n    background: #eee;\n    border: 1px solid rgb(200, 91, 91);\n    cursor: pointer;\n    border-radius: 50%;\n    font-size: 20px;\n    line-height: 1;\n  }\n  \n  .edgebutton:hover {\n    box-shadow: 0 0 6px 2px rgba(0, 0, 0, 0.08);\n  }\n  \n  .edgebutton-foreignobject div {\n    width: 40px;\n    height: 40px;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    min-height: 40px;\n  }\n\n  .custom-file-upload{\n    width: 100%;\n    height: 100%;\n    display: flex;\n    align-items: center;\n    justify-content: space-evenly;\n  }"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
