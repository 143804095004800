import React from 'react'

const PinterestIcon = () => {
  return (
   <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
   <g clip-path="url(#clip0_6599_19486)">
   <path d="M15 29.8769C23.2844 29.8769 30 23.1894 30 14.9388C30 6.68875 23.2844 0 15 0C6.71562 0 0 6.68813 0 14.9388C0 23.1887 6.71562 29.8769 15 29.8769Z" fill="white"/>
   <path d="M15 0C6.71625 0 0 6.68875 0 14.9388C0 21.27 3.95062 26.68 9.53125 28.8562C9.395 27.6762 9.28375 25.8563 9.58 24.5656C9.85188 23.3975 11.3331 17.1394 11.3331 17.1394C11.3331 17.1394 10.8894 16.2419 10.8894 14.9263C10.8894 12.8481 12.0988 11.2994 13.605 11.2994C14.8888 11.2994 15.5062 12.2581 15.5062 13.4019C15.5062 14.68 14.6912 16.5981 14.2594 18.3813C13.9012 19.8687 15.0125 21.0863 16.4813 21.0863C19.1481 21.0863 21.1975 18.2831 21.1975 14.25C21.1975 10.6719 18.6175 8.17625 14.9263 8.17625C10.6544 8.17625 8.14812 11.3606 8.14812 14.6556C8.14812 15.9344 8.64187 17.3119 9.25937 18.0619C9.3119 18.1187 9.34865 18.1883 9.36598 18.2637C9.3833 18.3392 9.38059 18.4178 9.35812 18.4919C9.24687 18.9587 8.9875 19.9794 8.93812 20.1887C8.87687 20.4587 8.71625 20.52 8.43188 20.385C6.55562 19.5125 5.3825 16.795 5.3825 14.5944C5.3825 9.885 8.815 5.55687 15.2963 5.55687C20.4938 5.55687 24.5431 9.24563 24.5431 14.1881C24.5431 19.34 21.2838 23.4838 16.7656 23.4838C15.2469 23.4838 13.815 22.6962 13.3331 21.7625L12.3956 25.3156C12.0619 26.6188 11.1481 28.2419 10.5306 29.2375C11.9707 29.6791 13.4687 29.903 14.975 29.9019C23.2594 29.9019 29.975 23.2131 29.975 14.9631C30 6.68813 23.2844 0 15 0Z" fill="#E60019"/>
   </g>
   <defs>
   <clipPath id="clip0_6599_19486">
   <rect width="30" height="30" fill="white"/>
   </clipPath>
   </defs>
   </svg>
   
  )
}

export default PinterestIcon