import React from "react";

const DarkBlueTagIcon = props => {
    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path d="M8.55117 15.5C7.93769 15.5026 7.34822 15.2618 6.912 14.8305L1.1684 9.08685C0.937335 8.85124 0.758636 8.5695 0.643999 8.26005C0.529361 7.95061 0.481369 7.62044 0.503164 7.29116L0.750995 3.29107C0.797522 2.63301 1.07998 2.01384 1.54646 1.54735C2.01295 1.08087 2.63212 0.798412 3.29018 0.751885L7.29027 0.504054C7.61942 0.483626 7.9492 0.532255 8.25845 0.646818C8.56769 0.76138 8.84956 0.939345 9.08596 1.16929L14.8296 6.91289C15.2805 7.40207 15.5194 8.04984 15.4941 8.71465C15.4689 9.37945 15.1815 10.0072 14.6948 10.4608L10.4469 14.6957C10.1996 14.9482 9.90476 15.1493 9.57941 15.2873C9.25405 15.4254 8.90459 15.4977 8.55117 15.5ZM7.44679 1.58669H7.34679L3.34236 1.83017C2.94966 1.86277 2.58125 2.03357 2.30262 2.3122C2.02398 2.59084 1.85318 2.95925 1.82058 3.35194L1.59014 7.34768C1.57456 7.52549 1.59757 7.70457 1.6576 7.87267C1.71763 8.04077 1.81328 8.19391 1.93798 8.32162L7.66854 14.0609C7.79418 14.1824 7.9437 14.2764 8.10762 14.337C8.27154 14.3977 8.44628 14.4235 8.62073 14.4131C9.02143 14.3886 9.39842 14.2149 9.67728 13.9261L13.9295 9.67817C14.2106 9.42745 14.3817 9.07631 14.4061 8.70051C14.4305 8.32471 14.3062 7.95438 14.06 7.66943L8.32942 1.93887C8.21328 1.82419 8.07535 1.73394 7.92375 1.67345C7.77216 1.61296 7.60998 1.58346 7.44679 1.58669Z" fill="#003E66" stroke="#003E66" stroke-width="0.5" />
            <path d="M5.38107 7.23023C4.89098 7.23023 4.42097 7.03554 4.07443 6.689C3.72789 6.34246 3.5332 5.87245 3.5332 5.38236C3.53324 5.01665 3.64179 4.65918 3.84511 4.35519C4.04843 4.05121 4.33738 3.8144 4.67537 3.67473C5.01336 3.53507 5.38519 3.49883 5.74378 3.57062C6.10238 3.6424 6.43161 3.81898 6.68979 4.07798C6.94731 4.33639 7.12258 4.66525 7.19351 5.0231C7.26444 5.38096 7.22785 5.7518 7.08836 6.0889C6.94887 6.426 6.71272 6.71427 6.40967 6.91737C6.10662 7.12048 5.75023 7.22934 5.38542 7.23023H5.38107ZM5.38107 4.62147C5.22935 4.62015 5.08071 4.66421 4.95422 4.74799C4.82773 4.83178 4.72918 4.95147 4.67122 5.09168C4.61327 5.2319 4.59855 5.38624 4.62897 5.53488C4.65939 5.68352 4.73355 5.81967 4.84193 5.92585C4.98739 6.06409 5.1804 6.14118 5.38107 6.14118C5.58174 6.14118 5.77475 6.06409 5.92021 5.92585C6.06253 5.7825 6.14239 5.5887 6.14239 5.38671C6.14239 5.18472 6.06253 4.99091 5.92021 4.84757C5.84984 4.776 5.76592 4.71915 5.67336 4.68034C5.5808 4.64152 5.48144 4.62151 5.38107 4.62147Z" fill="#003E66" stroke="#003E66" stroke-width="0.5" />
        </svg>
    )
}

export default DarkBlueTagIcon;