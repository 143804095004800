import * as React from 'react';

const UsersIcon = props => {
    return (
        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path d="M5.25 15C5.25 13.7574 6.92893 12.75 9 12.75C11.0711 12.75 12.75 13.7574 12.75 15" stroke="#666666" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M13.5 10.6871C14.8244 11.0343 15.75 11.8273 15.75 12.7499" stroke="#666666" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M4.5 10.6871C3.17557 11.0343 2.25 11.8273 2.25 12.7499" stroke="#666666" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M9 10.5C10.2426 10.5 11.25 9.49264 11.25 8.25C11.25 7.00736 10.2426 6 9 6C7.75736 6 6.75 7.00736 6.75 8.25C6.75 9.49264 7.75736 10.5 9 10.5Z" stroke="#666666" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M13.5 7.67708C13.9603 7.26509 14.25 6.66637 14.25 6C14.25 4.75736 13.2426 3.75 12 3.75C11.4237 3.75 10.8981 3.96664 10.5 4.32292" stroke="#666666" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M4.5 7.67708C4.03969 7.26509 3.75 6.66637 3.75 6C3.75 4.75736 4.75736 3.75 6 3.75C6.57627 3.75 7.10193 3.96664 7.5 4.32292" stroke="#666666" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
    )
}

export default UsersIcon;