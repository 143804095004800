import React from 'react'

const Visa = () => {
  return (
   <svg width="61" height="30" viewBox="0 0 61 30" fill="none" xmlns="http://www.w3.org/2000/svg">
   <path d="M27.8404 21.7666H24.5765L26.6164 8.24167H29.8806L27.8404 21.7666ZM21.8307 8.24167L18.719 17.5443L18.3508 15.541L18.3511 15.5418L17.2529 9.45944C17.2529 9.45944 17.1201 8.24167 15.7046 8.24167H10.5604L10.5 8.47068C10.5 8.47068 12.0731 8.82378 13.9142 10.0166L16.7498 21.767H20.1506L25.3434 8.24167H21.8307ZM47.503 21.7666H50.5L47.887 8.24131H45.2632C44.0516 8.24131 43.7565 9.24924 43.7565 9.24924L38.8887 21.7666H42.2911L42.9715 19.7577H47.1207L47.503 21.7666ZM43.9115 16.9824L45.6264 11.921L46.5912 16.9824H43.9115ZM39.1439 11.4941L39.6097 8.58971C39.6097 8.58971 38.1724 8 36.674 8C35.0543 8 31.2079 8.76373 31.2079 12.4775C31.2079 15.9716 35.7223 16.015 35.7223 17.8503C35.7223 19.6857 31.673 19.3568 30.3367 18.1994L29.8514 21.2363C29.8514 21.2363 31.3089 22 33.5355 22C35.7629 22 39.1231 20.7558 39.1231 17.3695C39.1231 13.853 34.5681 13.5255 34.5681 11.9966C34.5684 10.4674 37.7472 10.6638 39.1439 11.4941Z" fill="#2566AF"/>
   <path d="M18.3511 15.5413L17.2529 9.45898C17.2529 9.45898 17.1201 8.24121 15.7046 8.24121H10.5604L10.5 8.47022C10.5 8.47022 12.9725 9.02303 15.3441 11.0942C17.6117 13.0739 18.3511 15.5413 18.3511 15.5413Z" fill="#E6A540"/>
   <rect x="1" y="0.5" width="59" height="29" rx="3.5" stroke="#D9D9D9"/>
   </svg>
   
  )
}

export default Visa