import * as React from 'react';

const UploadBlueIcon = props => {
    return (
        <svg width="19" height="20" viewBox="0 0 19 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <g clip-path="url(#clip0_1548_9708)">
                <path d="M5.54102 15.5417H4.74935C2.56322 15.5417 0.791016 13.7695 0.791016 11.5833C0.791016 9.48538 2.42314 7.76864 4.48692 7.63356C5.37457 5.75669 7.28533 4.45833 9.49935 4.45833C12.2913 4.45833 14.601 6.52301 14.985 9.20881C15.0036 9.20849 15.0223 9.20833 15.041 9.20833C16.7899 9.20833 18.2077 10.6261 18.2077 12.375C18.2077 14.1239 16.7899 15.5417 15.041 15.5417H13.4577" stroke="#4AACEA" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M11.875 10.7917L9.5 8.41667M9.5 8.41667L7.125 10.7917M9.5 8.41667L9.5 14.75" stroke="#4AACEA" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
            </g>
            <defs>
                <clipPath id="clip0_1548_9708">
                    <rect width="19" height="19" fill="white" transform="translate(0 0.5)" />
                </clipPath>
            </defs>
        </svg>

    )
}

export default UploadBlueIcon;