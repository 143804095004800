import React from "react";

const ExcelIcon = props => {
    return (
        <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <g clip-path="url(#clip0_6484_18521)">
                <path d="M18.75 22.5H16.8047L14.9297 19.4531L13.0547 22.5H11.25L13.9219 18.3516L11.4141 14.4609H13.2891L15.0234 17.3437L16.7109 14.4609H18.5391L16.0078 18.4453L18.75 22.5Z" fill="#007732" stroke="#007732" />
                <path d="M24.375 29.5312H5.625C4.33594 29.5312 3.28125 28.4766 3.28125 27.1875V2.8125C3.28125 1.52344 4.33594 0.46875 5.625 0.46875H15.1641C15.7969 0.46875 16.3828 0.703125 16.8281 1.14844L26.0391 10.3594C26.4844 10.8047 26.7188 11.3906 26.7188 12.0234V27.1875C26.7188 28.4766 25.6641 29.5312 24.375 29.5312ZM5.625 1.40625C4.85156 1.40625 4.21875 2.03906 4.21875 2.8125V27.1875C4.21875 27.9609 4.85156 28.5938 5.625 28.5938H24.375C25.1484 28.5938 25.7812 27.9609 25.7812 27.1875V12.0234C25.7812 11.6484 25.6406 11.2969 25.3594 11.0391L16.1484 1.82812C15.8906 1.54688 15.5391 1.40625 15.1641 1.40625H5.625Z" fill="#007732" stroke="#007732" />
            </g>
            <defs>
                <clipPath id="clip0_6484_18521">
                    <rect width="30" height="30" fill="white" />
                </clipPath>
            </defs>
        </svg>
    )
}

export default ExcelIcon;