import React from 'react'

const YoutubeIcon = () => {
  return (
   <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
   <g clip-path="url(#clip0_7107_20129)">
   <path d="M15 30C23.2843 30 30 23.2843 30 15C30 6.71573 23.2843 0 15 0C6.71573 0 0 6.71573 0 15C0 23.2843 6.71573 30 15 30Z" fill="#E21A20"/>
   <path fill-rule="evenodd" clip-rule="evenodd" d="M23.1137 12.6373C22.9989 11.0553 22.7106 9.42171 20.6879 9.30218C16.898 9.08612 13.0989 9.08612 9.30903 9.30218C7.28637 9.41937 6.99809 11.0553 6.88325 12.6373C6.76548 14.2101 6.76548 15.7895 6.88325 17.3623C6.99809 18.9444 7.28637 20.578 9.30903 20.6975C13.0989 20.9136 16.898 20.9136 20.6879 20.6975C22.7106 20.5803 22.9989 18.9444 23.1137 17.3623C23.2315 15.7895 23.2315 14.2101 23.1137 12.6373ZM13.359 17.1092V12.4217L17.8122 14.7655L13.359 17.1092Z" fill="white"/>
   </g>
   <defs>
   <clipPath id="clip0_7107_20129">
   <rect width="30" height="30" fill="white"/>
   </clipPath>
   </defs>
   </svg>
   
  )
}

export default YoutubeIcon