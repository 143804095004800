import React from 'react';

export const GoogleIcon = () => {
  return (
    <>
      <svg
        width="22"
        height="22"
        viewBox="0 0 22 22"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M22 11.2477C22 10.5235 21.9345 9.83603 21.8221 9.16687H11.2388V13.301H17.2984C17.0268 14.6577 16.2307 15.8035 15.0506 16.5827V19.3327H18.6658C20.7825 17.4168 22 14.5935 22 11.2477Z"
          fill="#4285F4"
        />
        <path
          d="M11.2387 22C14.2733 22 16.8114 21.0101 18.6658 19.3326L15.0506 16.5826C14.0391 17.2426 12.756 17.6459 11.2387 17.6459C8.30726 17.6459 5.82533 15.7117 4.93559 13.0992H1.20801V15.9317C3.05306 19.5251 6.8462 22 11.2387 22Z"
          fill="#34A853"
        />
        <path
          d="M4.93576 13.0994C4.70162 12.4394 4.57986 11.7336 4.57986 11.0003C4.57986 10.2669 4.71098 9.56109 4.93576 8.90109V6.0686H1.20818C0.44019 7.5536 0 9.22192 0 11.0003C0 12.7786 0.44019 14.4469 1.20818 15.9319L4.93576 13.0994Z"
          fill="#FBBC05"
        />
        <path
          d="M11.2387 4.35415C12.8965 4.35415 14.3763 4.91332 15.547 6.00415L18.7501 2.86916C16.8114 1.09083 14.2733 0 11.2387 0C6.8462 0 3.05306 2.47499 1.20801 6.06832L4.93559 8.90081C5.82533 6.28832 8.30726 4.35415 11.2387 4.35415Z"
          fill="#EA4335"
        />
      </svg>
    </>
  );
};
