import * as React from 'react';

const FbHaha = props => {
    return (
        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M18 9C18 11.3914 17.0486 13.68 15.3643 15.3643C13.68 17.0486 11.3914 18 9 18C6.60857 18 4.32 17.0486 2.63572 15.3643C0.951429 13.68 0 11.3914 0 9C0 6.60857 0.951429 4.32 2.63572 2.63571C4.33286 0.951429 6.62143 0 9 0C11.3914 0 13.68 0.951429 15.3643 2.63571C17.0614 4.32 18 6.60857 18 9Z" fill="url(#paint0_linear_6460_18312)"/>
        <path d="M3.38135 9.01327C3.38135 11.2761 4.51278 15.7504 9.01278 15.7504C13.4999 15.7504 14.6442 11.2761 14.6442 9.01327C14.6442 8.83327 12.8313 7.88184 9.01278 7.88184C5.1942 7.88184 3.38135 8.83327 3.38135 9.01327Z" fill="url(#paint1_linear_6460_18312)"/>
        <path d="M5.11719 14.0661C6.01719 15.1847 7.26433 15.7504 9.02576 15.7504C10.7872 15.7504 12.0086 15.1847 12.9215 14.0661C12.3043 13.4104 11.1215 12.3818 9.02576 12.3818C6.93005 12.3818 5.73433 13.3976 5.11719 14.0661Z" fill="url(#paint2_linear_6460_18312)"/>
        <path d="M6.99423 4.6671C7.28995 4.87282 7.55995 5.18139 7.45709 5.55425C7.37995 5.8371 7.23852 5.96567 6.98138 5.96567C6.10709 5.97853 5.06566 6.24853 4.16566 6.65996C4.08852 6.69853 3.94709 6.76282 3.79281 6.74996C3.65138 6.74996 3.50995 6.68567 3.41995 6.47996C3.34281 6.29996 3.34281 6.04282 3.72852 5.81139C4.34566 5.43853 5.14281 5.27139 5.87566 5.12996C5.41281 4.79567 4.92423 4.49996 4.39709 4.26853C3.92138 4.04996 3.97281 3.75424 4.03709 3.5871C4.17852 3.2271 4.71852 3.36853 5.24566 3.61282C5.86281 3.90853 6.45423 4.25567 6.99423 4.6671ZM11.0185 4.6671C11.5585 4.26853 12.15 3.90853 12.7671 3.62567C13.2942 3.38139 13.8342 3.23996 13.9757 3.59996C14.0399 3.7671 14.0914 4.06282 13.6157 4.28139C13.0885 4.51282 12.5999 4.80853 12.1371 5.14282C12.8699 5.28424 13.6671 5.45139 14.2842 5.82424C14.6828 6.05567 14.6699 6.32567 14.5928 6.49282C14.5028 6.69853 14.3614 6.74996 14.2199 6.76282C14.0657 6.76282 13.9242 6.69853 13.8471 6.67282C12.9471 6.27424 11.9057 5.99139 11.0314 5.97853C10.7742 5.97853 10.6328 5.84996 10.5557 5.5671C10.4528 5.16853 10.7228 4.87282 11.0185 4.6671Z" fill="#2A3755"/>
        <defs>
        <linearGradient id="paint0_linear_6460_18312" x1="9.00428" y1="1.845" x2="9.00428" y2="18" gradientUnits="userSpaceOnUse">
        <stop stop-color="#FEEA70"/>
        <stop offset="1" stop-color="#F69B30"/>
        </linearGradient>
        <linearGradient id="paint1_linear_6460_18312" x1="9.0042" y1="7.87541" x2="9.0042" y2="15.7504" gradientUnits="userSpaceOnUse">
        <stop stop-color="#472315"/>
        <stop offset="1" stop-color="#8B3A0E"/>
        </linearGradient>
        <linearGradient id="paint2_linear_6460_18312" x1="9.00997" y1="12.3754" x2="9.00997" y2="15.1395" gradientUnits="userSpaceOnUse">
        <stop stop-color="#FC607C"/>
        <stop offset="1" stop-color="#D91F3A"/>
        </linearGradient>
        </defs>
        </svg>
                
    )
}

export default FbHaha;