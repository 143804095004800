import React from 'react';

const BlurTime = () => {
    return (
        <div>
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M20.1459 20.1254H5.38426V16.5126C5.38533 16.1078 5.58535 15.7269 5.92498 15.4827L9.35868 13.0264C9.88753 12.6481 10.217 12.0701 10.2614 11.4427C10.3059 10.8153 10.0609 10.2006 9.59013 9.75855L5.78738 6.18874C5.53019 5.94602 5.38504 5.61608 5.38426 5.27161V2.88316H20.1459C20.4 2.88316 20.606 2.68744 20.606 2.4459C20.606 2.20451 20.4 2.00879 20.1459 2.00879H3.83235C3.5781 2.00879 3.37207 2.20451 3.37207 2.4459C3.37207 2.68744 3.5781 2.88316 3.83235 2.88316H4.46385V5.27161C4.46539 5.84955 4.7087 6.40332 5.14019 6.81086L8.94294 10.3805V10.3804C9.22355 10.6439 9.36945 11.0103 9.34298 11.3842C9.31663 11.758 9.12016 12.1025 8.80503 12.3278L5.37116 14.7842C4.80117 15.1941 4.46554 15.8333 4.46385 16.5126V20.1255L3.83235 20.1254C3.5781 20.1254 3.37207 20.3213 3.37207 20.5627C3.37207 20.8042 3.5781 20.9999 3.83235 20.9999H20.1459C20.4 20.9999 20.606 20.8042 20.606 20.5627C20.606 20.3213 20.4 20.1254 20.1459 20.1254V20.1254Z" fill="#D9D9D9" stroke="#D9D9D9" />
                <path d="M18.9183 21C18.7961 21 18.679 20.954 18.5927 20.8719C18.5064 20.7899 18.4579 20.6787 18.4581 20.5627V16.5128C18.4568 16.108 18.2566 15.727 17.9169 15.483L14.4831 13.0266C13.9543 12.6485 13.6247 12.0704 13.5803 11.443C13.5361 10.8156 13.7812 10.2007 14.2521 9.7588L18.0545 6.18898C18.3118 5.94642 18.4571 5.61632 18.458 5.27185V2.44614C18.458 2.20475 18.664 2.00903 18.9183 2.00903C19.1724 2.00903 19.3784 2.20476 19.3784 2.44614V5.27185C19.3769 5.84994 19.1334 6.40371 18.7016 6.81111L14.8993 10.3809C14.6186 10.6441 14.4723 11.0104 14.4987 11.3844C14.5252 11.7583 14.7215 12.1027 15.0368 12.328L18.4707 14.7844C19.0408 15.1942 19.3768 15.8336 19.3785 16.5128V20.5627C19.3786 20.6787 19.3301 20.7899 19.2438 20.8719C19.1575 20.954 19.0404 21 18.9183 21H18.9183Z" fill="#D9D9D9" stroke="#D9D9D9" />
            </svg>
        </div>
    );
};

export default BlurTime;
