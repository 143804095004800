import * as React from 'react';

const FbLike = props => {
    return (
        <svg width="15" height="15" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_6022_37818)">
        <path d="M12 0C8.8174 0 5.76516 1.26428 3.51472 3.51472C1.26428 5.76516 0 8.8174 0 12C0 15.1826 1.26428 18.2348 3.51472 20.4853C5.76516 22.7357 8.8174 24 12 24C15.1826 24 18.2348 22.7357 20.4853 20.4853C22.7357 18.2348 24 15.1826 24 12C24 8.8174 22.7357 5.76516 20.4853 3.51472C18.2348 1.26428 15.1826 0 12 0Z" fill="url(#paint0_linear_6022_37818)"/>
        <path d="M18.243 11.007C18.507 11.1915 18.75 11.3745 18.75 12.018C18.75 12.663 18.4065 12.924 18.039 13.1055C18.189 13.35 18.237 13.6455 18.1725 13.9245C18.057 14.4405 17.5845 14.841 17.1645 14.9595C17.346 15.2505 17.403 15.537 17.187 15.8895C16.9095 16.332 16.668 16.5 15.6 16.5H11.25C9.768 16.5 9 15.681 9 15V11.4975C9 9.6525 11.2005 8.085 11.2005 6.8025L11.0415 5.205C11.034 5.1075 11.0535 4.869 11.1285 4.8C11.2485 4.6815 11.58 4.5 12.081 4.5C12.408 4.5 12.6255 4.5615 12.882 4.6845C13.7535 5.1 13.98 6.1515 13.98 6.9975C13.98 7.404 13.359 8.622 13.275 9.0435C13.275 9.0435 14.5755 8.7555 16.0935 8.745C17.685 8.736 18.717 9.03 18.717 10.008C18.717 10.3995 18.3885 10.7925 18.243 11.007ZM5.4 10.5H6.6C6.8387 10.5 7.06761 10.5948 7.2364 10.7636C7.40518 10.9324 7.5 11.1613 7.5 11.4V17.1C7.5 17.3387 7.40518 17.5676 7.2364 17.7364C7.06761 17.9052 6.8387 18 6.6 18H5.4C5.16131 18 4.93239 17.9052 4.7636 17.7364C4.59482 17.5676 4.5 17.3387 4.5 17.1V11.4C4.5 11.1613 4.59482 10.9324 4.7636 10.7636C4.93239 10.5948 5.16131 10.5 5.4 10.5Z" fill="white"/>
        </g>
        <defs>
        <linearGradient id="paint0_linear_6022_37818" x1="12" y1="0" x2="12" y2="24" gradientUnits="userSpaceOnUse">
        <stop stop-color="#18AFFF"/>
        <stop offset="1" stop-color="#0062DF"/>
        </linearGradient>
        <clipPath id="clip0_6022_37818">
        <rect width="24" height="24" fill="white"/>
        </clipPath>
        </defs>
        </svg>
        
    )
}

export default FbLike;