import * as React from 'react';

const LikesIcon = props => {
    return (
        <svg width="20" height="20" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0.537447 8.67972H4.2973C4.59335 8.67972 4.83475 8.90928 4.83475 9.19569V17.484C4.83475 17.7682 4.59563 18 4.2973 18H0.537447C0.241395 18 0 17.7704 0 17.484V9.1935C0.00227732 8.90928 0.241395 8.67972 0.537447 8.67972ZM6.14876 16.052V10.1139C6.15104 8.6513 6.5769 7.21926 7.38079 5.97743C7.87042 5.22533 8.96808 4.02067 9.58296 3.39757C9.7196 3.25765 10.2161 2.84662 10.2161 2.62143C10.2161 2.33283 10.2502 1.86715 10.2616 0.99918C10.2684 0.448227 10.7353 -0.00434122 11.3092 3.14219e-05H11.3342C12.3385 0.017522 12.6596 0.90954 12.6596 0.90954C12.6596 0.90954 13.5182 2.53616 12.7165 4.52571C12.0402 6.20699 11.9013 6.34254 11.9013 6.34254C11.9013 6.34254 11.6826 6.64644 12.375 6.63332C12.375 6.63332 16.6017 6.61583 16.7474 6.61583C17.0594 6.61583 18.0546 6.88912 17.9977 8.1069C17.9567 9.00767 17.3737 9.3225 17.0753 9.42525C17.0366 9.43837 17.0161 9.47991 17.0321 9.51708C17.0389 9.53238 17.0503 9.5455 17.0662 9.55425C17.3714 9.72478 17.9954 10.1555 17.9817 10.8158C17.9635 11.6159 17.6287 11.9111 16.9319 12.0948C16.8932 12.1035 16.8681 12.1428 16.8795 12.18C16.884 12.1997 16.8977 12.215 16.9137 12.2237C17.1778 12.368 17.631 12.72 17.6037 13.4328C17.5763 14.1586 17.0298 14.4144 16.7315 14.5019C16.6928 14.5128 16.67 14.5522 16.6814 14.5893C16.6859 14.6046 16.6973 14.6199 16.711 14.6287C16.9 14.7533 17.1801 15.0353 17.1664 15.6147C17.1596 15.9208 17.0526 16.1438 16.9182 16.3034C16.7064 16.5548 16.3739 16.686 16.0369 16.6882L6.65888 16.7057C6.6566 16.7035 6.14876 16.7057 6.14876 16.052Z" fill="#5E92FF" />
        </svg>
    )
}

export default LikesIcon;