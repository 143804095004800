import React from 'react';

const OpenSmall = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M17 18C17 17.4477 17.4477 17 18 17C18.5523 17 19 17.4477 19 18C19 18.5523 18.5523 19 18 19C17.4477 19 17 18.5523 17 18Z" fill="#AD2F24" stroke="#AD2F24" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M11 18C11 17.4477 11.4477 17 12 17C12.5523 17 13 17.4477 13 18C13 18.5523 12.5523 19 12 19C11.4477 19 11 18.5523 11 18Z" fill="#AD2F24" stroke="#AD2F24" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M5 18C5 17.4477 5.44772 17 6 17C6.55228 17 7 17.4477 7 18C7 18.5523 6.55228 19 6 19C5.44772 19 5 18.5523 5 18Z" fill="#AD2F24" stroke="#AD2F24" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M17 12C17 11.4477 17.4477 11 18 11C18.5523 11 19 11.4477 19 12C19 12.5523 18.5523 13 18 13C17.4477 13 17 12.5523 17 12Z" fill="#AD2F24" stroke="#AD2F24" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M11 12C11 11.4477 11.4477 11 12 11C12.5523 11 13 11.4477 13 12C13 12.5523 12.5523 13 12 13C11.4477 13 11 12.5523 11 12Z" fill="#AD2F24" stroke="#AD2F24" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M5 12C5 11.4477 5.44772 11 6 11C6.55228 11 7 11.4477 7 12C7 12.5523 6.55228 13 6 13C5.44772 13 5 12.5523 5 12Z" fill="#AD2F24" stroke="#AD2F24" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M17 6C17 5.44772 17.4477 5 18 5C18.5523 5 19 5.44772 19 6C19 6.55228 18.5523 7 18 7C17.4477 7 17 6.55228 17 6Z" fill="#AD2F24" stroke="#AD2F24" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M11 6C11 5.44772 11.4477 5 12 5C12.5523 5 13 5.44772 13 6C13 6.55228 12.5523 7 12 7C11.4477 7 11 6.55228 11 6Z" fill="#AD2F24" stroke="#AD2F24" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M5 6C5 5.44772 5.44772 5 6 5C6.55228 5 7 5.44772 7 6C7 6.55228 6.55228 7 6 7C5.44772 7 5 6.55228 5 6Z" fill="#AD2F24" stroke="#AD2F24" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />


    </svg>
  );
};

export default OpenSmall;
