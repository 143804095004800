import React from 'react';

const BlueTick = () => {
  return (
    <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_6924_15695)">
    <path d="M6.85786 1.74349C7.73397 0.764836 9.2662 0.764837 10.1423 1.74349L10.2265 1.83752C10.673 2.33629 11.322 2.6051 11.9904 2.56815L12.1164 2.56118C13.4279 2.48868 14.5114 3.57213 14.4389 4.88365L14.4319 5.00966C14.395 5.67807 14.6638 6.32705 15.1625 6.77356L15.2566 6.85774C16.2352 7.73385 16.2352 9.26608 15.2566 10.1422L15.1625 10.2264C14.6638 10.6729 14.395 11.3219 14.4319 11.9903L14.4389 12.1163C14.5114 13.4278 13.4279 14.5113 12.1164 14.4387L11.9904 14.4318C11.322 14.3948 10.673 14.6636 10.2265 15.1624L10.1423 15.2564C9.2662 16.2351 7.73397 16.2351 6.85786 15.2564L6.77368 15.1624C6.32717 14.6636 5.67819 14.3948 5.00978 14.4318L4.88377 14.4387C3.57225 14.5113 2.4888 13.4278 2.56131 12.1163L2.56827 11.9903C2.60523 11.3219 2.33641 10.6729 1.83764 10.2264L1.74361 10.1422C0.764958 9.26608 0.764959 7.73385 1.74361 6.85773L1.83764 6.77356C2.33641 6.32705 2.60523 5.67807 2.56827 5.00966L2.56131 4.88365C2.4888 3.57213 3.57225 2.48868 4.88377 2.56118L5.00978 2.56815C5.67819 2.6051 6.32717 2.33629 6.77368 1.83752L6.85786 1.74349Z" fill="#1DA1F2"/>
    <path d="M5.63623 8.50001L7.54532 10.4091L10.8862 6.11365" stroke="white" stroke-width="1.43182" stroke-linecap="round" stroke-linejoin="round"/>
    </g>
    <defs>
    <clipPath id="clip0_6924_15695">
    <rect width="17" height="17" fill="white"/>
    </clipPath>
    </defs>
    </svg>
  );
};
export default BlueTick;