import React from 'react';

const PlanExpired = () => {
  return (
    <svg
      width="320"
      height="250"
      viewBox="0 0 420 315"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_6108_26389)">
        <path d="M0 276.858H420V276.648H0V276.858Z" fill="#E7E8E5" />
        <path
          d="M350.094 290.213H377.916V290.423H350.094V290.213Z"
          fill="#E7E8E5"
        />
        <path
          d="M270.922 292.499H278.224V292.709H270.922V292.499Z"
          fill="#E7E8E5"
        />
        <path
          d="M333.133 282.597H349.254V282.386H333.133V282.597Z"
          fill="#E7E8E5"
        />
        <path
          d="M44.0654 283.803H80.3478V284.014H44.0654V283.803Z"
          fill="#E7E8E5"
        />
        <path
          d="M87.8271 283.803H93.1469V284.014H87.8271V283.803Z"
          fill="#E7E8E5"
        />
        <path
          d="M110.436 287.361H189.123V287.571H110.436V287.361Z"
          fill="#E7E8E5"
        />
        <path
          d="M36.8886 0.918823C34.3602 0.918823 32.3037 2.98264 32.3037 5.51749V234.254C32.3037 236.791 34.3602 238.854 36.8886 238.854H199.092C201.618 238.854 203.675 236.791 203.675 234.254V5.51749C203.675 2.98264 201.618 0.918823 199.092 0.918823H36.8886ZM199.092 239.064H36.8886C34.2447 239.064 32.0938 236.907 32.0938 234.254V5.51749C32.0938 2.86633 34.2447 0.708145 36.8886 0.708145H199.092C201.734 0.708145 203.885 2.86633 203.885 5.51749V234.254C203.885 236.907 201.734 239.064 199.092 239.064Z"
          fill="#E7E8E5"
        />
        <path
          d="M218.577 0.918823C216.05 0.918823 213.993 2.98264 213.993 5.51749V234.254C213.993 236.791 216.05 238.854 218.577 238.854H380.78C383.308 238.854 385.365 236.791 385.365 234.254V5.51749C385.365 2.98264 383.308 0.918823 380.78 0.918823H218.577ZM380.78 239.064H218.577C215.934 239.064 213.783 236.907 213.783 234.254V5.51749C213.783 2.86633 215.934 0.708145 218.577 0.708145H380.78C383.424 0.708145 385.575 2.86633 385.575 5.51749V234.254C385.575 236.907 383.424 239.064 380.78 239.064Z"
          fill="#E7E8E5"
        />
        <path
          d="M85.0801 133.627H330.18V18.5827H85.0801V133.627Z"
          fill="#E2E2E0"
        />
        <path
          d="M86.8057 133.627H333.873V18.5827H86.8057V133.627Z"
          fill="#EDEDEB"
        />
        <path
          d="M85.0801 139.495H330.18V133.626H85.0801V139.495Z"
          fill="#E2E2E0"
        />
        <path
          d="M92.957 139.495H340.024V133.626H92.957V139.495Z"
          fill="#EDEDEB"
        />
        <path
          d="M91.9746 128.44H328.703V23.7682H91.9746V128.44Z"
          fill="#FAFAF9"
        />
        <path
          d="M117.376 128.441L131.763 23.7683H154.215L139.827 128.441H117.376Z"
          fill="white"
        />
        <path
          d="M94.3676 123.279C94.1635 123.279 93.998 123.113 93.998 122.909V27.1428C93.998 26.9372 94.1635 26.772 94.3676 26.772C94.5718 26.772 94.7372 26.9372 94.7372 27.1428V122.909C94.7372 123.113 94.5718 123.279 94.3676 123.279Z"
          fill="#EDEDEB"
        />
        <path
          d="M144.65 128.441L159.038 23.7683H167.791L153.403 128.441H144.65Z"
          fill="white"
        />
        <path
          d="M328.047 128.44H328.703V23.7682H328.047V128.44Z"
          fill="#E2E2E0"
        />
        <path
          d="M335.095 31.5869H333.873V25.7832H334.62L335.095 31.5869Z"
          fill="#F0F0EE"
        />
        <path
          d="M328.703 31.5869H333.873V25.7834H328.703V31.5869Z"
          fill="#EAEAE7"
        />
        <path
          d="M328.046 31.5869H166.715L167.513 25.7832H328.046V31.5869Z"
          fill="#EEEEEC"
        />
        <path
          d="M91.9748 31.5869H89.995L89.5195 25.7832H91.9748V31.5869Z"
          fill="#EAEAE7"
        />
        <path
          d="M157.963 31.5869H153.139L153.937 25.7832H158.76L157.963 31.5869ZM130.689 31.5869H94.7374V27.1425C94.7374 26.9369 94.5719 26.7717 94.3678 26.7717C94.1637 26.7717 93.9982 26.9369 93.9982 27.1425V31.5869H91.9746V25.7832H131.487L130.689 31.5869Z"
          fill="#EEEEEC"
        />
        <path
          d="M153.139 31.5869H130.688L131.486 25.7832H153.937L153.139 31.5869Z"
          fill="#F0F0EE"
        />
        <path
          d="M94.7372 31.5869H93.998V27.1425C93.998 26.9369 94.1635 26.7717 94.3676 26.7717C94.5718 26.7717 94.7372 26.9369 94.7372 27.1425V31.5869Z"
          fill="#EAEAE7"
        />
        <path
          d="M166.716 31.5869H157.963L158.76 25.7832H167.514L166.716 31.5869Z"
          fill="#F0F0EE"
        />
        <path
          d="M328.047 31.5869H328.703V25.7834H328.047V31.5869Z"
          fill="#E5E5E3"
        />
        <path
          d="M335.095 41.1055H333.873V35.3018H334.62L335.095 41.1055Z"
          fill="#F0F0EE"
        />
        <path
          d="M333.873 41.1055H328.703V35.3018H333.873V41.1055ZM91.9748 41.1055H89.995L89.5195 35.3018H91.9748V41.1055Z"
          fill="#EAEAE7"
        />
        <path
          d="M328.047 41.1055H315.357C316.007 37.1582 311.024 36.8304 309.35 36.8304C309.015 36.8304 308.813 36.8431 308.813 36.8431C309.067 36.3197 309.173 35.7981 309.163 35.3018H328.047V41.1055ZM286.23 41.1055H165.407L166.205 35.3018H286.316C286.346 35.5638 286.436 35.8301 286.6 36.0948C287.882 38.1602 286.351 39.1555 286.23 41.1055ZM156.654 41.1055H151.831L152.629 35.3018H157.452L156.654 41.1055ZM129.38 41.1055H94.7374V35.3018H130.178L129.38 41.1055ZM93.9982 41.1055H91.9746V35.3018H93.9982V41.1055ZM296.227 36.329C295.031 36.329 293.939 36.0122 293.083 35.3018H300.339C298.959 35.9523 297.531 36.329 296.227 36.329Z"
          fill="#EEEEEC"
        />
        <path
          d="M151.831 41.1055H129.38L130.178 35.3018H152.629L151.831 41.1055Z"
          fill="#F0F0EE"
        />
        <path
          d="M93.998 41.1055H94.737V35.302H93.998V41.1055Z"
          fill="#EAEAE7"
        />
        <path
          d="M165.407 41.1055H156.654L157.452 35.3018H166.205L165.407 41.1055Z"
          fill="#F0F0EE"
        />
        <path
          d="M328.047 41.1055H328.703V35.302H328.047V41.1055Z"
          fill="#E5E5E3"
        />
        <path
          d="M335.095 50.624H333.873V44.8203H334.62L335.095 50.624Z"
          fill="#F0F0EE"
        />
        <path
          d="M333.873 50.624H328.703V44.8203H333.873V50.624ZM91.9748 50.624H89.995L89.5195 44.8203H91.9748V50.624Z"
          fill="#EAEAE7"
        />
        <path
          d="M328.047 50.624H311.832C311.682 48.1583 312.822 47.2844 314.032 44.8203H328.047V50.624ZM291.195 50.624H164.099L164.896 44.8203H291.838C291.273 46.6254 291.095 48.6268 291.195 50.624ZM155.346 50.624H150.523L151.321 44.8203H156.143L155.346 50.624ZM128.072 50.624H94.7374V44.8203H128.869L128.072 50.624ZM93.9982 50.624H91.9746V44.8203H93.9982V50.624Z"
          fill="#EEEEEC"
        />
        <path
          d="M150.523 50.624H128.072L128.869 44.8203H151.321L150.523 50.624Z"
          fill="#F0F0EE"
        />
        <path d="M93.998 50.624H94.737V44.8206H93.998V50.624Z" fill="#EAEAE7" />
        <path
          d="M164.099 50.624H155.346L156.143 44.8203H164.896L164.099 50.624Z"
          fill="#F0F0EE"
        />
        <path
          d="M328.047 50.624H328.703V44.8206H328.047V50.624Z"
          fill="#E5E5E3"
        />
        <path
          d="M335.095 60.1426H333.873V54.338H334.62L335.095 60.1426Z"
          fill="#F0F0EE"
        />
        <path
          d="M333.873 60.1426H328.703V54.338H333.873V60.1426ZM91.9748 60.1426H89.995L89.5195 54.338H91.9748V60.1426Z"
          fill="#EAEAE7"
        />
        <path
          d="M328.047 60.1426H310.296C310.296 59.9445 310.295 59.7448 310.295 59.546C311.703 58.6089 312.089 56.4103 312.103 54.338H328.047V60.1426ZM293.805 60.1426H162.79L163.588 54.338H291.687C292.065 56.1161 292.625 57.7796 293.277 59.1676L293.306 59.2316C293.457 59.5493 293.624 59.8527 293.805 60.1426ZM154.037 60.1426H149.215L150.012 54.338H154.835L154.037 60.1426ZM126.763 60.1426H94.7374V54.338H127.561L126.763 60.1426ZM93.9982 60.1426H91.9746V54.338H93.9982V60.1426Z"
          fill="#EEEEEC"
        />
        <path
          d="M149.215 60.1426H126.764L127.562 54.338H150.012L149.215 60.1426Z"
          fill="#F0F0EE"
        />
        <path
          d="M93.998 60.1426H94.737V54.3381H93.998V60.1426Z"
          fill="#EAEAE7"
        />
        <path
          d="M162.79 60.1426H154.037L154.835 54.338H163.588L162.79 60.1426Z"
          fill="#F0F0EE"
        />
        <path
          d="M328.047 60.1426H328.703V54.3381H328.047V60.1426Z"
          fill="#E5E5E3"
        />
        <path
          d="M335.095 69.6611H333.873V63.8566H334.62L335.095 69.6611Z"
          fill="#F0F0EE"
        />
        <path
          d="M333.873 69.6611H328.703V63.8566H333.873V69.6611ZM91.9748 69.6611H89.995L89.5195 63.8566H91.9748V69.6611Z"
          fill="#EAEAE7"
        />
        <path
          d="M328.047 69.6611H311.027C310.595 67.7929 310.42 65.8521 310.349 63.8566H328.047V69.6611ZM297.528 69.6611H161.482L162.279 63.8566H298.577C299.4 64.1178 300.258 64.2544 301.118 64.2611C301.088 66.5204 300.249 68.5504 297.528 69.6611ZM152.729 69.6611H147.906L148.704 63.8566H153.526L152.729 69.6611ZM125.455 69.6611H94.7374V63.8566H126.252L125.455 69.6611ZM93.9982 69.6611H91.9746V63.8566H93.9982V69.6611Z"
          fill="#EEEEEC"
        />
        <path
          d="M147.907 69.6611H125.456L126.253 63.8566H148.705L147.907 69.6611Z"
          fill="#F0F0EE"
        />
        <path
          d="M93.998 69.6611H94.737V63.8567H93.998V69.6611Z"
          fill="#EAEAE7"
        />
        <path
          d="M161.483 69.6611H152.73L153.528 63.8566H162.28L161.483 69.6611Z"
          fill="#F0F0EE"
        />
        <path
          d="M328.047 69.6611H328.703V63.8567H328.047V69.6611Z"
          fill="#E5E5E3"
        />
        <path
          d="M335.095 79.1797H333.873V73.376H334.62L335.095 79.1797Z"
          fill="#F0F0EE"
        />
        <path
          d="M333.873 79.1797H331.406C330.689 77.5853 329.798 75.9749 328.703 74.5625V73.376H333.873V79.1797ZM91.9748 79.1797H89.995L89.5195 73.376H91.9748V79.1797Z"
          fill="#EAEAE7"
        />
        <path
          d="M286.258 79.1797H160.173L160.971 73.376H288.546C287.769 74.2827 287.186 75.3681 286.863 76.5707C286.661 77.3274 286.456 78.1929 286.258 79.1797ZM151.421 79.1797H146.598L147.395 73.376H152.219L151.421 79.1797ZM124.147 79.1797H94.7374V73.376H124.945L124.147 79.1797ZM93.9982 79.1797H91.9746V73.376H93.9982V79.1797ZM328.047 73.7695C327.928 73.6364 327.807 73.5041 327.684 73.376H328.047V73.7695Z"
          fill="#EEEEEC"
        />
        <path
          d="M146.598 79.1797H124.146L124.944 73.376H147.395L146.598 79.1797Z"
          fill="#F0F0EE"
        />
        <path
          d="M93.998 79.1797H94.737V73.3762H93.998V79.1797Z"
          fill="#EAEAE7"
        />
        <path
          d="M160.175 79.1797H151.422L152.22 73.376H160.973L160.175 79.1797Z"
          fill="#F0F0EE"
        />
        <path
          d="M328.703 74.5625C328.492 74.2903 328.274 74.0257 328.047 73.7695V73.376H328.703V74.5625Z"
          fill="#E5E5E3"
        />
        <path
          d="M331.355 276.648H368.761V162.546H331.355V276.648Z"
          fill="#E2E2E0"
        />
        <path
          d="M358.771 276.648H368.76V260.897H348.299L358.771 276.648Z"
          fill="#FAFAF9"
        />
        <path
          d="M229.367 276.648H266.772V162.546H229.367V276.648Z"
          fill="#E2E2E0"
        />
        <path
          d="M258.055 271.227H368.759V162.546H258.055V271.227Z"
          fill="#FAFAF9"
        />
        <path
          d="M268.043 276.648H258.055V260.897H278.516L268.043 276.648Z"
          fill="#FAFAF9"
        />
        <path
          d="M265.803 230.242H361.011V203.045H265.803V230.242Z"
          fill="#EDEDEB"
        />
        <path
          d="M265.803 263.252H361.011V236.054H265.803V263.252Z"
          fill="#EDEDEB"
        />
        <path
          d="M342.126 205.953H284.691C282.522 205.953 280.764 204.189 280.764 202.013V200.69H346.052V202.013C346.052 204.189 344.294 205.953 342.126 205.953Z"
          fill="#FAFAF9"
        />
        <path
          d="M265.803 197.231H361.011V170.034H265.803V197.231Z"
          fill="#EDEDEB"
        />
        <path
          d="M342.126 172.941H284.691C282.522 172.941 280.764 171.178 280.764 169.003V167.678H346.052V169.003C346.052 171.178 344.294 172.941 342.126 172.941Z"
          fill="#FAFAF9"
        />
        <path
          d="M342.126 238.963H284.691C282.522 238.963 280.764 237.199 280.764 235.024V233.699H346.052V235.024C346.052 237.199 344.294 238.963 342.126 238.963Z"
          fill="#FAFAF9"
        />
        <path
          d="M44.8702 186.874C46.9541 180.872 63.386 189.034 70.0544 198.98C74.4953 205.603 77.2085 212.675 78.4754 222.172C78.3232 211.336 78.1509 199.691 77.9819 188.702C76.6337 185.963 73.5542 179.646 71.3394 174.641C63.9494 157.94 46.3763 151.721 40.3053 141.176C32.3513 127.36 32.3965 106.635 39.1684 99.1564C45.053 92.6566 64.5406 136.602 66.7355 141.512C74.285 158.399 76.4798 170.931 77.895 183.073C77.7338 172.752 77.5796 163.277 77.4533 155.966C77.0405 132.118 76.5247 124.448 76.2891 122.088C75.4754 120.934 74.515 119.592 73.9565 118.871C69.2404 112.78 58.7774 105.529 53.5481 96.6746C45.913 83.7467 47.1464 68.2738 52.502 62.5123C58.1015 56.4886 68.001 70.7252 73.2569 84.8338C78.5125 98.9415 76.2665 112.443 77.7407 124.16C78.0488 126.458 78.3662 135.187 78.6631 147.293C80.2578 134.267 83.4337 125.539 89.3186 117.407C96.5586 107.405 102.807 95.2243 108.773 96.7985C114.737 98.3727 111.527 114.489 106.808 125.804C102.605 135.88 81.8735 145.93 78.8193 154.032C79.1789 170.442 79.5005 191.232 79.7285 210.589C81.3629 200.591 80.2391 199.751 86.9372 184.444C90.9188 175.343 107.243 164.121 109.189 170.373C111.099 176.51 108.177 188.444 102.014 196.72C97.0433 203.398 86.0132 203.39 83.461 207.532C82.8654 208.5 80.8964 212.205 79.7719 214.335C80.0383 237.967 80.1577 258.828 80.0255 265.94L78.9555 266.013C79.0067 264.176 78.8774 252.154 78.6674 236.245C75.9893 227.781 57.9584 222.839 52.3882 214.036C46.559 204.823 42.3702 194.074 44.8702 186.874Z"
          fill="#E2E2E0"
        />
        <path
          d="M61.8255 276.648H96.4342C98.325 276.648 99.9538 275.312 100.33 273.453L102.787 261.302H55.4727L57.9293 273.453C58.3053 275.312 59.9345 276.648 61.8255 276.648Z"
          fill="#EDEDEB"
        />
        <path
          d="M54.4823 266.013H103.775C104.957 266.013 105.95 265.122 106.082 263.945L107.09 254.961C107.244 253.582 106.168 252.373 104.784 252.373H53.4743C52.0899 252.373 51.0131 253.582 51.1682 254.961L52.1762 263.945C52.3082 265.122 53.3011 266.013 54.4823 266.013Z"
          fill="#EDEDEB"
        />
        <path
          d="M149.385 276.648H119.812V222.059H149.385V276.648Z"
          fill="#DADBD8"
        />
        <path
          d="M224.767 276.648H125.736L115.333 202.576H214.364L224.767 276.648Z"
          fill="#E2E2E0"
        />
        <path
          d="M239.214 276.648H129.671L119.269 202.576H228.811L239.214 276.648Z"
          fill="#EDEDEB"
        />
        <path
          d="M140.394 265.068L131.631 214.155H218.088L226.852 265.068H140.394Z"
          fill="white"
        />
        <path
          d="M177.542 265.068L148.154 214.155H157.374L186.762 265.068H177.542Z"
          fill="#FAFAF9"
        />
        <path
          d="M190.671 265.068L161.283 214.155H177.005L206.392 265.068H190.671Z"
          fill="#FAFAF9"
        />
        <path
          d="M372.866 305.165C372.866 310.435 299.949 314.707 210 314.707C120.051 314.707 47.1328 310.435 47.1328 305.165C47.1328 299.895 120.051 295.622 210 295.622C299.949 295.622 372.866 299.895 372.866 305.165Z"
          fill="#F3F2F1"
        />
        <path
          d="M64.9033 141.084C64.7186 141.084 64.5627 140.942 64.5473 140.754L62.6809 118.253C62.6646 118.055 62.8106 117.882 63.0075 117.866C63.1922 117.851 63.3765 117.996 63.393 118.194L65.2593 140.696C65.2758 140.893 65.1297 141.065 64.9329 141.082L64.9033 141.084Z"
          fill="#0062E0"
        />
        <path
          d="M66.0112 154.448C65.8267 154.448 65.6709 154.307 65.6551 154.119L65.0901 147.307C65.0737 147.11 65.2197 146.937 65.4166 146.92C65.6053 146.896 65.7857 147.05 65.8021 147.248L66.3672 154.06C66.3836 154.257 66.2376 154.431 66.0407 154.447L66.0112 154.448Z"
          fill="#0062E0"
        />
        <path
          d="M254.986 218.477H81.5486C77.1232 218.477 73.2011 214.845 72.833 210.405L63.065 92.6187C62.6969 88.1785 66.0162 84.5464 70.4416 84.5464H243.88C248.305 84.5464 252.227 88.1785 252.595 92.6187L262.363 210.405C262.731 214.845 259.412 218.477 254.986 218.477Z"
          fill="white"
        />
        <path
          d="M70.4416 84.8835C68.4017 84.8835 66.5444 85.6832 65.2123 87.136C63.8726 88.5964 63.2288 90.5329 63.3994 92.5917L73.1667 210.378C73.5221 214.659 77.2823 218.141 81.5487 218.141H254.987C257.028 218.141 258.885 217.341 260.217 215.889C261.556 214.429 262.201 212.492 262.03 210.435L252.262 92.6473C251.906 88.3663 248.146 84.8835 243.88 84.8835H70.4416ZM254.987 218.814H81.5487C76.9418 218.814 72.8822 215.055 72.499 210.435L62.7317 92.6473C62.5446 90.3998 63.2507 88.2804 64.7185 86.6801C66.1793 85.0874 68.212 84.2101 70.4416 84.2101H243.88C248.487 84.2101 252.546 87.9703 252.929 92.5917L262.698 210.378C262.884 212.626 262.178 214.744 260.71 216.344C259.25 217.937 257.217 218.814 254.987 218.814Z"
          fill="#0062E0"
        />
        <path
          d="M243.88 84.5469H70.4418C66.0164 84.5469 62.6971 88.1789 63.065 92.6191H252.595C252.227 88.1789 248.305 84.5469 243.88 84.5469Z"
          fill="#0062E0"
        />
        <path
          d="M74.5015 88.583C74.5812 89.5437 73.8692 90.3232 72.9107 90.3232C71.9522 90.3232 71.111 89.5437 71.0315 88.583C70.9515 87.6207 71.6639 86.8412 72.622 86.8412C73.5804 86.8412 74.4218 87.6207 74.5015 88.583Z"
          fill="white"
        />
        <path
          d="M81.5574 88.583C81.6372 89.5437 80.9252 90.3232 79.9666 90.3232C79.0085 90.3232 78.1673 89.5437 78.0871 88.583C78.0074 87.6207 78.7198 86.8412 79.6783 86.8412C80.6364 86.8412 81.4781 87.6207 81.5574 88.583Z"
          fill="white"
        />
        <path
          d="M88.6129 88.583C88.6927 89.5437 87.9803 90.3232 87.0219 90.3232C86.0635 90.3232 85.2226 89.5437 85.1428 88.583C85.063 87.6207 85.7753 86.8412 86.7329 86.8412C87.6914 86.8412 88.5331 87.6207 88.6129 88.583Z"
          fill="white"
        />
        <path
          d="M239.935 203.042H94.0395C90.5216 203.042 87.4043 200.155 87.112 196.626L80.2986 114.471C80.0055 110.942 82.6446 108.054 86.162 108.054H232.057C235.575 108.054 238.693 110.942 238.986 114.471L245.798 196.626C246.091 200.155 243.453 203.042 239.935 203.042Z"
          fill="#EDEDEB"
        />
        <path
          d="M232.95 214.208C232.034 214.208 231.094 214.11 230.139 213.903L180.084 203.042H239.936C242.68 203.042 244.89 201.285 245.589 198.836C247.118 206.747 240.935 214.209 232.95 214.208Z"
          fill="#E4E7E5"
        />
        <path
          d="M239.935 203.042H180.083L156.829 197.997C147.344 195.938 143.417 184.522 149.617 177.033L194.815 122.441C197.448 119.26 201.102 117.754 204.725 117.754C209.719 117.754 214.654 120.619 216.764 125.91L244.874 196.409C245.197 197.217 245.433 198.03 245.588 198.836C244.889 201.285 242.679 203.042 239.935 203.042Z"
          fill="#D3D9D5"
        />
        <path
          d="M86.0371 183.669C80.4826 183.628 76.4769 178.014 78.4725 172.622L83.9042 157.949L86.0371 183.669Z"
          fill="#E4E7E5"
        />
        <path
          d="M86.0976 183.67C86.0783 183.67 86.0573 183.669 86.0371 183.669L83.9043 157.949L94.2154 130.095C95.4972 126.632 98.6775 124.751 101.896 124.751C104.232 124.751 106.589 125.742 108.255 127.837L138.484 165.843C142.525 170.922 139.344 178.472 132.897 179.107L86.925 183.629C86.6461 183.656 86.3706 183.67 86.0976 183.67Z"
          fill="#D3D9D5"
        />
        <path
          d="M210.407 108.695L168.155 170.244C162.399 178.627 167.498 190.152 177.555 191.495L255.226 201.866C266.007 203.305 274.024 192.09 269.205 182.308L233.788 110.389C229.193 101.059 216.292 100.123 210.407 108.695Z"
          fill="#0062E0"
        />
        <path
          d="M179.4 185.978C176.575 185.601 174.12 183.814 172.831 181.199C171.543 178.584 171.692 175.686 173.229 173.447L215.587 111.743C217.124 109.506 219.728 108.394 222.552 108.772C225.377 109.148 227.833 110.935 229.12 113.551L264.627 185.65C265.915 188.266 265.767 191.163 264.23 193.402C262.694 195.639 260.09 196.751 257.265 196.374L179.4 185.978Z"
          fill="white"
        />
        <path
          d="M218.55 174.032L219.899 174.212C222.745 174.592 224.935 177.188 224.79 180.012C224.646 182.835 222.222 184.816 219.376 184.436L218.027 184.256C215.181 183.876 212.992 181.28 213.136 178.456C213.281 175.634 215.705 173.652 218.55 174.032ZM218.945 130.583L223.892 131.243C227.074 131.667 229.522 134.57 229.36 137.726L229.028 144.2C229.016 144.437 228.99 144.672 228.948 144.904L225.427 164.412C224.92 167.224 222.36 169.042 219.456 168.654C216.544 168.267 214.201 165.783 213.995 162.869L212.568 142.708C212.551 142.475 212.548 142.242 212.56 142.01L212.892 135.529C213.054 132.372 215.764 130.157 218.945 130.583Z"
          fill="#0062E0"
        />
        <path
          d="M110.464 118.103L98.8632 162.238C97.3268 168.083 102.41 173.526 108.324 172.369L153.324 163.561C159.647 162.323 162.119 154.571 157.685 149.881L124.287 114.553C119.884 109.897 112.096 111.897 110.464 118.103Z"
          fill="#0062E0"
        />
        <path
          d="M108.238 168.794C106.601 169.114 104.899 168.539 103.685 167.254C102.47 165.969 101.997 164.244 102.419 162.639L114.049 118.392C114.471 116.787 115.7 115.637 117.337 115.317C118.973 114.997 120.675 115.573 121.89 116.857L155.373 152.274C156.588 153.559 157.061 155.283 156.639 156.889C156.217 158.494 154.988 159.643 153.352 159.963L108.238 168.794Z"
          fill="white"
        />
        <path
          d="M127.629 154.298L128.411 154.146C130.059 153.823 131.77 154.923 132.231 156.605C132.692 158.286 131.729 159.91 130.08 160.232L129.299 160.385C127.65 160.709 125.94 159.607 125.479 157.926C125.018 156.246 125.98 154.621 127.629 154.298ZM119.519 128.78L122.386 128.219C124.229 127.857 126.141 129.089 126.657 130.969L127.714 134.822C127.753 134.963 127.782 135.106 127.804 135.25L129.594 147.348C129.852 149.092 128.783 150.647 127.1 150.976C125.413 151.307 123.639 150.301 122.966 148.635L118.312 137.103C118.257 136.969 118.212 136.833 118.174 136.695L117.115 132.836C116.6 130.957 117.676 129.141 119.519 128.78Z"
          fill="#0062E0"
        />
        <path
          d="M326.223 128.026L319.705 130.15L324.949 136.375C324.949 136.375 327.419 134.266 328.766 131.899C329.329 130.91 329.004 129.652 328.024 129.079L326.223 128.026Z"
          fill="#F4AA8A"
        />
        <path
          d="M316.705 135.259L320.614 140.022L324.95 136.375L319.706 130.15L316.705 135.259Z"
          fill="#F4AA8A"
        />
        <path
          d="M328.327 77.162C329.919 81.5424 331.381 85.9245 332.764 90.3672C334.13 94.8209 335.432 99.267 336.542 103.941L336.939 105.731L337.124 106.668L337.215 107.136L337.238 107.252L337.265 107.432L337.314 107.79C337.548 109.698 337.349 111.197 337.087 112.588C336.538 115.342 335.646 117.676 334.666 119.944C332.669 124.446 330.275 128.49 327.473 132.413L323.118 129.995C324.66 125.796 326.185 121.471 327.326 117.306C327.875 115.23 328.348 113.138 328.488 111.327C328.552 110.441 328.507 109.613 328.386 109.239L328.025 107.921L327.557 106.364C326.239 102.144 324.736 97.8066 323.248 93.4962C321.769 89.1773 320.276 84.8273 318.844 80.5008L328.327 77.162Z"
          fill="#F4AA8A"
        />
        <path
          d="M292.048 51.0928C292.16 51.6195 291.975 52.115 291.636 52.2001C291.297 52.2861 290.932 51.9296 290.821 51.4046C290.71 50.8796 290.895 50.3841 291.235 50.2981C291.573 50.2122 291.938 50.5678 292.048 51.0928Z"
          fill="#4D4D4D"
        />
        <path
          d="M292.022 52.1014C292.022 52.1014 291.483 55.4301 290.494 57.2208C291.595 57.8562 293.07 57.0649 293.07 57.0649L292.022 52.1014Z"
          fill="#DB764A"
        />
        <path
          d="M290.662 47.9043C290.563 47.9043 290.466 47.8579 290.404 47.7703C290.305 47.6279 290.34 47.4315 290.482 47.3313C290.533 47.2967 291.744 46.4633 293.326 47.1172C293.486 47.1838 293.563 47.3675 293.496 47.5285C293.431 47.6886 293.247 47.7661 293.087 47.6995C291.819 47.1754 290.852 47.8411 290.842 47.8478L290.662 47.9043Z"
          fill="#4D4D4D"
        />
        <path
          d="M310.054 52.763C310.64 58.8794 309.774 64.8028 311.14 70.1219C311.14 70.1219 309.624 75.0576 300.611 75.0576C290.7 75.0576 296.065 70.1219 296.065 70.1219C301.523 68.8267 301.539 64.8002 300.742 61.0199L310.054 52.763Z"
          fill="#F4AA8A"
        />
        <path
          d="M313.061 71.8156C313.831 70.2532 314.342 68.0411 312.746 67.4816C310.99 66.8647 301.611 66.6599 298.207 67.817C294.804 68.974 295.634 72.1426 295.634 72.1426L313.061 71.8156Z"
          fill="#4D4D4D"
        />
        <path
          d="M334.555 292.937C333.415 293.462 332.485 293.637 332.023 293.404C331.819 293.301 331.699 293.12 331.652 292.85C331.63 292.717 331.672 292.662 331.712 292.628C332.067 292.323 333.505 292.62 334.555 292.937ZM333.31 293.75C333.962 293.595 334.633 293.293 335.095 293.058L335.189 292.893L335.073 292.742C334.778 292.641 332.174 291.779 331.489 292.366C331.383 292.458 331.266 292.625 331.314 292.908C331.379 293.288 331.566 293.558 331.868 293.712C332.25 293.904 332.774 293.877 333.31 293.75Z"
          fill="#0062E0"
        />
        <path
          d="M332.959 290.543C333.097 290.511 333.235 290.529 333.378 290.599C334.004 290.905 334.51 292.04 334.732 292.617C333.877 292.237 332.702 291.279 332.681 290.793C332.679 290.748 332.674 290.644 332.885 290.565L332.959 290.543ZM335.058 293.072L335.142 293.023L335.182 292.852C335.155 292.767 334.506 290.767 333.528 290.289C333.273 290.165 333.017 290.149 332.766 290.243C332.378 290.387 332.332 290.659 332.338 290.808C332.375 291.632 334.15 292.847 334.974 293.071L335.058 293.072Z"
          fill="#0062E0"
        />
        <path
          d="M288.826 298.39C287.596 298.637 286.65 298.592 286.254 298.257C286.08 298.111 286.004 297.907 286.021 297.633C286.029 297.5 286.083 297.455 286.129 297.431C286.546 297.216 287.876 297.838 288.826 298.39ZM287.428 298.892C288.098 298.892 288.82 298.753 289.324 298.631L289.453 298.491L289.374 298.318C289.11 298.152 286.773 296.712 285.973 297.126C285.849 297.189 285.697 297.325 285.679 297.612C285.655 297.997 285.774 298.303 286.033 298.522C286.361 298.797 286.877 298.892 287.428 298.892Z"
          fill="#0062E0"
        />
        <path
          d="M287.821 295.691C287.963 295.691 288.092 295.74 288.216 295.841C288.755 296.284 288.987 297.506 289.071 298.118C288.326 297.55 287.403 296.348 287.493 295.868C287.502 295.825 287.521 295.722 287.744 295.695L287.821 295.691ZM289.283 298.637L289.377 298.608L289.455 298.45C289.448 298.361 289.275 296.264 288.433 295.575C288.214 295.395 287.968 295.321 287.702 295.353C287.292 295.404 287.184 295.658 287.156 295.804C287.003 296.615 288.453 298.207 289.202 298.615L289.283 298.637Z"
          fill="#0062E0"
        />
        <path
          d="M297.08 298.373H290.45L290.383 282.97H297.013L297.08 298.373Z"
          fill="#F4AA8A"
        />
        <path
          d="M343.276 292.674L336.892 293.848L332.314 278.691L338.698 277.516L343.276 292.674Z"
          fill="#F4AA8A"
        />
        <path
          d="M335.679 292.145L342.929 290.425C343.192 290.362 343.456 290.494 343.565 290.741L345.975 296.244C346.224 296.816 345.868 297.496 345.256 297.63C342.717 298.186 341.459 298.327 338.271 299.084C336.309 299.549 332.309 300.713 329.601 301.356C326.952 301.984 326.009 299.372 327.069 298.863C331.826 296.583 333.402 294.62 334.711 292.794C334.946 292.464 335.293 292.236 335.679 292.145Z"
          fill="#4D4D4D"
        />
        <path
          d="M290.523 297.603H297.763C298.034 297.603 298.262 297.792 298.311 298.057L299.393 303.97C299.504 304.582 299.018 305.163 298.393 305.152C295.783 305.108 292.015 304.954 288.731 304.954C284.894 304.954 283.525 305.165 279.024 305.165C276.302 305.165 275.653 302.403 276.802 302.153C282.032 301.016 284.316 300.893 288.905 298.121C289.404 297.82 289.95 297.603 290.523 297.603Z"
          fill="#4D4D4D"
        />
        <path
          d="M325.919 71.84C332.107 76.2077 333.971 87.4679 333.971 87.4679L319.946 94.6006C319.946 94.6006 316.384 87.6761 314.824 80.6378C313.348 73.9762 321.521 68.7338 325.919 71.84Z"
          fill="#0062E0"
        />
        <path
          d="M322.514 93.2871C322.647 91.809 322.801 90.3124 322.977 88.7997L324.433 92.302L322.514 93.2871Z"
          fill="#0758C0"
        />
        <path
          d="M284.668 101.058C284.716 108.481 285.163 117.716 286.265 129.18H321.734C322.169 123.953 319.993 99.3168 325.272 74.6508C325.666 72.8163 324.442 71.0297 322.594 70.7415C320.333 70.3893 317.241 69.9856 314.217 69.8381C309.695 69.6207 303.139 69.4758 299.145 69.8381C296.972 70.0362 294.847 70.3842 293.097 70.7264C290.071 71.3171 287.664 73.584 286.863 76.5705C286.098 79.4256 285.317 83.8152 284.923 90.4364C284.742 93.4878 284.643 97.001 284.668 101.058Z"
          fill="#0062E0"
        />
        <path
          d="M284.949 89.9658C284.995 89.2268 285.048 88.5147 285.106 87.8296C285.051 88.5138 284.999 89.2251 284.952 89.965C284.951 89.965 284.95 89.965 284.949 89.9658Z"
          fill="#BFC0BE"
        />
        <path
          d="M284.951 89.9648C284.998 89.2249 285.05 88.5137 285.106 87.8294C285.133 87.5075 285.162 87.1907 285.192 86.8805C286.292 86.2991 287.35 85.769 288.35 85.2802L295.859 87.0912C293.517 88.8188 289.033 89.6109 284.951 89.9648Z"
          fill="#0758C0"
        />
        <path
          d="M297.05 291.533L297.04 289.375H297.042L297.05 291.533ZM290.42 291.533H290.417L290.408 289.375H290.41L290.42 291.533Z"
          fill="#C3C5C2"
        />
        <path
          d="M297.05 291.533H290.42L290.41 289.375H297.04L297.05 291.533Z"
          fill="#DB764A"
        />
        <path
          d="M341.059 285.333L340.447 283.309L340.451 283.308L341.064 285.332L341.059 285.333Z"
          fill="#C3C5C2"
        />
        <path
          d="M334.679 286.507L334.121 284.661L340.449 283.309L341.06 285.333L334.679 286.507Z"
          fill="#DB764A"
        />
        <path
          d="M309.107 45.5981C310.078 52.1476 310.975 55.9195 308.457 60.0454C304.671 66.2519 296.187 65.2887 293.308 59.2347C290.716 53.7858 289.563 43.9902 295.255 39.8433C300.861 35.7587 308.136 39.0478 309.107 45.5981Z"
          fill="#F4AA8A"
        />
        <path
          d="M295.544 50.3803C293.087 49.2975 293.321 45.4109 294.068 43.7727C291.221 44.7561 287.105 45.3174 286.363 42.4151C285.62 39.5128 288.14 38.5757 286.601 36.0948C285.061 33.6139 289.961 30.8979 292.247 34.3622C294.534 37.8266 300.174 36.3047 303.821 32.9583C306.473 30.5229 310.354 33.6602 308.814 36.8431C308.814 36.8431 316.794 36.3291 315.208 41.7595C313.621 47.1907 311.381 47.3862 311.894 51.2483C312.242 53.8641 312.548 59.1748 309.421 59.9248C305.644 60.829 296.215 57.4717 295.544 50.3803Z"
          fill="#4D4D4D"
        />
        <path
          d="M296.742 63.0322C296.647 62.9758 296.552 62.9168 296.46 62.8553C296.553 62.9168 296.647 62.9749 296.742 63.0322ZM294.016 60.4654C293.943 60.36 293.874 60.253 293.805 60.1434H293.806C293.874 60.253 293.944 60.36 294.016 60.4654Z"
          fill="#BFC0BE"
        />
        <path
          d="M293.806 60.1426H293.805C293.624 59.8527 293.457 59.5493 293.306 59.2316L293.277 59.1676C293.288 59.1895 293.298 59.2122 293.308 59.2341C293.46 59.551 293.625 59.8535 293.806 60.1426Z"
          fill="#B7B9B7"
        />
        <path
          d="M296.743 63.0322C296.648 62.9749 296.554 62.9168 296.461 62.8553C295.524 62.2426 294.687 61.442 294.017 60.4654C293.945 60.36 293.875 60.253 293.807 60.1434C293.626 59.8544 293.461 59.5519 293.309 59.235C293.299 59.2131 293.289 59.1903 293.278 59.1684L293.266 59.1398C294.029 58.549 294.777 57.9929 295.499 57.4712L299.929 57.2816C301.42 58.3358 303.112 59.0976 304.717 59.5561C302.848 60.5977 300.313 61.0401 298.618 61.0401C298.537 61.0401 298.456 61.0392 298.379 61.0367C298.372 61.0367 298.365 61.0367 298.359 61.0367C297.48 61.0367 297.214 62.5721 296.743 63.0322Z"
          fill="#DB764A"
        />
        <path
          d="M304.716 59.5557C303.11 59.0972 301.418 58.3354 299.928 57.2812L307.043 56.9762C306.731 58.0784 305.845 58.9262 304.716 59.5557Z"
          fill="#4D4D4D"
        />
        <path
          d="M297.567 50.2873C297.752 51.966 297.442 53.5393 296.936 54.564C296.175 56.1053 294.985 55.1227 294.519 53.2267C294.1 51.5185 293.99 48.66 295.08 47.8199C296.153 46.9906 297.354 48.3668 297.567 50.2873Z"
          fill="#F4AA8A"
        />
        <path
          d="M298.848 129.18C298.848 129.18 304.416 180.012 307.814 199.056C311.534 219.91 330.805 285.369 330.805 285.369L342.681 282.832C342.681 282.832 329.418 221.594 327.631 200.946C325.692 178.536 321.73 129.18 321.73 129.18H298.848Z"
          fill="#0062E0"
        />
        <path
          d="M321.735 129.224C321.734 129.221 321.734 129.219 321.734 129.218C321.734 129.22 321.734 129.22 321.735 129.224ZM321.733 129.202C321.731 129.187 321.73 129.179 321.73 129.179C321.731 129.188 321.732 129.193 321.733 129.202Z"
          fill="#9B9D9D"
        />
        <path
          d="M330.805 285.369C330.805 285.369 311.534 219.91 307.814 199.056C307.768 198.803 307.723 198.545 307.677 198.281C308.965 185.536 310.53 163.642 307.396 153.157C308.594 139.658 309.579 129.18 309.579 129.18H298.848H321.73C321.73 129.18 321.731 129.187 321.733 129.203C321.733 129.206 321.733 129.214 321.733 129.218C321.733 129.219 321.733 129.221 321.734 129.224C325.785 179.686 325.788 179.649 327.631 200.946C329.418 221.594 342.681 282.832 342.681 282.832L340.448 283.309L334.12 284.661H334.118L330.805 285.369Z"
          fill="#4D4D4D"
        />
        <path
          d="M328.145 281.348L330.166 285.699L343.529 282.844L342.575 278.78L328.145 281.348Z"
          fill="#4D4D4D"
        />
        <path
          d="M307.676 198.293C307.615 197.948 307.555 197.594 307.494 197.231H307.497C307.558 197.59 307.618 197.939 307.676 198.28C307.676 198.285 307.676 198.29 307.676 198.293Z"
          fill="#BFC0BE"
        />
        <path
          d="M307.497 197.231H307.494C306.743 192.768 305.904 186.969 305.051 180.638C305.904 186.967 306.745 192.767 307.497 197.231Z"
          fill="#B6B8B6"
        />
        <path
          d="M307.677 198.28C306.879 193.695 305.973 187.477 305.051 180.637C305.787 171.532 306.635 161.735 307.396 153.157C310.529 163.642 308.964 185.535 307.677 198.28Z"
          fill="#181818"
        />
        <path
          d="M286.268 129.18C286.268 129.18 283.44 178.911 283.381 199.196C283.319 220.294 288.006 289.375 288.006 289.375H299.238C299.238 289.375 302.261 221.527 303.558 200.806C304.973 178.217 309.579 129.18 309.579 129.18H286.268Z"
          fill="#0062E0"
        />
        <path
          d="M299.238 289.375H288.007C288.007 289.375 283.379 221.168 283.381 199.603C283.381 199.465 283.381 199.33 283.382 199.196C283.441 178.911 286.268 129.18 286.268 129.18H309.579C309.579 129.18 308.594 139.658 307.396 153.157C306.635 161.735 305.788 171.532 305.051 180.638C304.565 186.655 304.126 192.37 303.794 197.231C303.711 198.44 303.635 199.594 303.566 200.689C303.564 200.728 303.561 200.767 303.558 200.806C303.466 202.293 303.364 204.023 303.254 205.953C302.892 212.361 302.451 220.975 301.996 230.242C301.94 231.383 301.884 232.541 301.827 233.699C301.742 235.444 301.658 237.197 301.572 238.963C301.172 247.272 300.776 255.716 300.428 263.252C300.298 266.053 300.175 268.729 300.061 271.227C299.973 273.147 299.889 274.961 299.813 276.648C299.81 276.719 299.806 276.788 299.804 276.859C299.458 284.453 299.238 289.375 299.238 289.375Z"
          fill="#4D4D4D"
        />
        <path
          d="M286.018 284.887L286.471 289.565H300.134L300.665 285.391L286.018 284.887Z"
          fill="#4D4D4D"
        />
        <path
          d="M300.316 82.2712C298.976 82.4018 297.837 82.4709 296.608 82.5071C295.401 82.5467 294.196 82.5627 292.99 82.5577C290.576 82.5299 288.156 82.4085 285.722 82.1346C283.288 81.8734 280.84 81.4773 278.377 80.8689C275.913 80.2748 273.432 79.4481 270.967 78.2313L270.688 78.0939C268.515 77.0211 267.621 74.386 268.689 72.2076C268.806 71.9708 268.956 71.7298 269.106 71.5242C270.253 69.977 271.366 68.8983 272.505 67.8778C273.639 66.8699 274.773 65.964 275.917 65.1028C278.198 63.3752 280.535 61.8491 282.879 60.3659C285.236 58.9038 287.615 57.5151 290.027 56.1929C292.458 54.8917 294.863 53.6133 297.385 52.4378C298.638 51.8546 300.126 52.3998 300.708 53.6572C301.211 54.7451 300.872 56.0075 299.961 56.7077L299.932 56.7288C297.82 58.3519 295.647 59.9656 293.54 61.6182C291.417 63.2547 289.305 64.8921 287.244 66.5615C285.174 68.214 283.16 69.9171 281.257 71.6295C280.307 72.4857 279.397 73.3587 278.578 74.2301C277.772 75.0905 277.006 75.9821 276.598 76.6588L274.737 69.9517C276.447 70.6183 278.377 71.0978 280.384 71.4627C282.396 71.8115 284.489 72.0399 286.609 72.1891C288.733 72.323 290.886 72.3938 293.047 72.3947C294.127 72.4023 295.21 72.3905 296.288 72.3576C297.345 72.318 298.492 72.2801 299.431 72.2278L299.547 72.2211C302.317 72.0643 304.689 74.1922 304.845 76.9714C304.997 79.6773 302.98 82.0057 300.316 82.2712Z"
          fill="#F4AA8A"
        />
        <path
          d="M305.02 51.076L300.801 52.0105C300.801 52.0105 295.183 51.3094 293.896 55.5583L297.225 58.4084L302.365 59.2241C303.179 59.353 304.014 59.2173 304.747 58.8364L306.059 58.153C306.662 57.8395 307.064 57.2395 307.127 56.562L307.438 53.2173C307.563 51.8656 306.341 50.7827 305.02 51.076Z"
          fill="#F4AA8A"
        />
        <path
          d="M296.821 87.1943C289.756 89.1612 281.701 86.703 281.701 86.703L284.664 70.8323C284.664 70.8323 291.683 70.2155 296.065 70.122C307.346 69.8818 309.558 83.6499 296.821 87.1943Z"
          fill="#0062E0"
        />
      </g>
      <defs>
        <clipPath id="clip0_6108_26389">
          <rect
            width="420"
            height="314"
            fill="white"
            transform="translate(0 0.707031)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default PlanExpired;
