import React from 'react'

const Running = () => {
  return (
    <svg
      width="64"
      height="64"
      viewBox="0 0 50 50"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ marginTop: '15px' }}
    >
      <rect width="50" height="50" rx="10" fill="#00AC4F" />
      <path
        d="M25.4087 22.9444C25.4087 23.9569 24.6151 24.7778 23.636 24.7778C22.657 24.7778 21.8633 23.9569 21.8633 22.9444C21.8633 21.9319 22.657 21.1111 23.636 21.1111C24.6151 21.1111 25.4087 21.9319 25.4087 22.9444Z"
        fill="white"
      />
      <path
        d="M30.7271 22.9444C30.7271 23.9569 29.9334 24.7778 28.9544 24.7778C27.9753 24.7778 27.1816 23.9569 27.1816 22.9444C27.1816 21.9319 27.9753 21.1111 28.9544 21.1111C29.9334 21.1111 30.7271 21.9319 30.7271 22.9444Z"
        fill="white"
      />
      <path
        d="M20.0904 22.9444C20.0904 23.9569 19.2967 24.7778 18.3176 24.7778C17.3386 24.7778 16.5449 23.9569 16.5449 22.9444C16.5449 21.9319 17.3386 21.1111 18.3176 21.1111C19.2967 21.1111 20.0904 21.9319 20.0904 22.9444Z"
        fill="white"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M37.818 30.2778C37.818 33.3154 35.4369 35.7778 32.4998 35.7778C29.5627 35.7778 27.1816 33.3154 27.1816 30.2778C27.1816 27.2403 29.5627 24.7778 32.4998 24.7778C35.4369 24.7778 37.818 27.2403 37.818 30.2778ZM32.2339 30.7541C32.0694 30.6559 31.968 30.4743 31.968 30.2778V28.3528C31.968 28.0491 32.2061 27.8028 32.4998 27.8028C32.7935 27.8028 33.0316 28.0491 33.0316 28.3528V29.9603L34.608 30.9015C34.8624 31.0534 34.9495 31.3898 34.8026 31.6528C34.6558 31.9159 34.3306 32.006 34.0762 31.8541L32.2339 30.7541Z"
        fill="white"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M14.4306 16.4303C14.7095 16.157 15.0919 16 15.4949 16H32.9596C33.3626 16 33.7451 16.157 34.024 16.4303C34.3021 16.7028 34.4545 17.068 34.4545 17.4444V21.9453C35.1597 22.0918 35.8308 22.3312 36.4545 22.65V17.4444C36.4545 16.5242 36.0813 15.646 35.4236 15.0017C34.7667 14.358 33.88 14 32.9596 14H15.4949C14.5746 14 13.6878 14.358 13.0309 15.0017C12.3733 15.646 12 16.5242 12 17.4444V34.5556C12 34.9326 12.212 35.2775 12.5484 35.4478C12.8848 35.618 13.2883 35.5846 13.5921 35.3614L18.3178 31.8889H25.0998C24.9256 31.2505 24.8285 30.5803 24.8191 29.8889H17.9899C17.7769 29.8889 17.5694 29.9569 17.3978 30.0831L14 32.5798V17.4444C14 17.068 14.1524 16.7028 14.4306 16.4303Z"
        fill="white"
      />
    </svg>
  );
}

export default Running
