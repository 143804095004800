import React from "react";

const LinkdinLike = props => {
    return (
        <svg width="20" height="22" viewBox="0 0 20 22" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M19.8642 20.8564L20 13.0196C20 12.4572 19.7866 11.8947 19.3598 11.4823L13.2679 5.50162C12.6859 4.92043 12.1815 4.26425 11.8129 3.53307L10.5712 1.1333C10.1056 0.233391 9.01919 -0.216564 8.02975 0.102154C6.94331 0.439621 6.32248 1.56451 6.63289 2.61441L7.60293 5.87659L8.43716 8.67006C8.55357 9.06377 8.24316 9.45748 7.83574 9.45748H1.91852C0.929079 9.45748 0.133649 10.2262 0.133649 11.1823H0.114249C0.114249 11.8198 0.463462 12.4009 1.04548 12.7009L1.4141 12.9071C1.4141 12.9071 -1.76762 14.707 1.4141 16.4693C1.4141 16.4693 -0.0215564 19.694 3.43177 19.694C3.43177 19.694 2.71395 22 5.81806 22H14.7036C15.4408 22 16.1393 21.8125 16.7795 21.4563L17.5167 21.0438C17.7883 20.8939 18.1181 20.8001 18.4285 20.8189H19.8448L19.8642 20.8564Z" fill="#0B86CA"/>
        </svg>
    )
}

export default LinkdinLike;